import { Injectable } from '@angular/core';
import { State } from '@ngrx/store';
import { IAppStore } from '@wingstop/store/app-store';
import { environment } from '../environments/environment';

declare var window: any;

@Injectable()
export class InteractionStudioService {
  constructor(private state: State<IAppStore>) {
    if (environment.envName !== 'prod') {
      document.addEventListener('wsApplePayProcessed', (event) => {
        console.log('wsApplePayProcessed', event);
      });
    }
  }
  setWindowWsData(key: string, data: any, subkey?: string) {
    if (!window.ws) {
      window.ws = {};
    }
    if (subkey) {
      if (!window.ws[key]) {
        window.ws[key] = {};
      }
      window.ws[key][subkey] = data;
    } else {
      window.ws[key] = data;
    }
  }

  // Get the user's email from the store and pass it to interaction studio login method
  interactionStudioWsLogin(): void {
    const appState = this.state.getValue().appState;
    const userEmail = appState?.authentication?.emailaddress;

    if (!userEmail) {
      return;
    }

    try {
      if ((window as any).wsLogin) {
        if (environment.envName !== 'prod') {
          console.log('set wsLogin: ', userEmail);
        }
        (window as any).wsLogin(userEmail);
      }
    } catch (e) {
      if (environment.envName !== 'prod') {
        console.error('interactionStudioWsLogin function error: ', e);
      }
    }
  }

  // Get the user's email from the store and pass it to interaction studio logout method
  interactionStudioWsLogout(): void {
    const appState = this.state.getValue().appState;
    const userEmail = appState?.authentication?.emailaddress;

    if (!userEmail) {
      return;
    }

    try {
      if ((window as any).wsLogout) {
        if (environment.envName !== 'prod') {
          console.log('set wsLogout: ', userEmail);
        }
        (window as any).wsLogout(userEmail);
      }
    } catch (e) {
      if (environment.envName !== 'prod') {
        console.error('interactionStudioWsLogout function error: ', e);
      }
    }
  }

  handleApplePayOrder(
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string
  ) {
    const eventData = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNumber,
    };

    const event = new CustomEvent('wsApplePayProcessed', {
      detail: eventData,
      bubbles: true,
    });
    document.dispatchEvent(event);
  }
}
