import {
  Component,
  EventEmitter,
  OnInit,
  Input,
  Output,
  AfterViewInit,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-range',
  templateUrl: './range.component.html',
  styleUrls: ['./range.component.scss'],
})
export class RangeComponent implements OnInit, AfterViewInit {
  @Input()
  public value: number = 0;
  @Input()
  public min: number = 0;
  @Input()
  public max: number = 10;
  @Input()
  public step: number = 1;
  @Input()
  public disabled: boolean = false;
  @Input()
  public tooltips: boolean = true;
  @Input()
  public variant: string = null;
  @Input()
  public buttonVariant: string = 'gold';
  @Input()
  public cssClasses: any = null;
  @Output()
  public valueChanged = new EventEmitter<any>();
  @Input()
  public control: UntypedFormControl = null;
  @Input()
  public minusButtonLabel: string = null;
  @Input()
  public plusButtonLabel: string = null;
  @Input()
  public extraClass = '';
  @Input()
  public colorfulHandle = false;
  @Input()
  public ariaControlsId: string = null;
  @Input()
  public ariaDescription: string = null;
  @Input()
  public ariaDescriptionIncrease: string = null;
  @Input()
  public ariaDescriptionDecrease: string = null;
  // @Input()
  // public ariaDescriptionSlider: string = null;
  public heatClass = '';

  public sliderCssClasses = {
    target: 'target',
    base: 'base',
    origin: 'origin',
    handle: 'handle',
    handleLower: 'handle-lower',
    handleUpper: 'handle-upper',
    horizontal: 'horizontal',
    vertical: 'vertical',
    background: 'background',
    connect: 'connect',
    connects: 'connects',
    ltr: 'ltr',
    rtl: 'rtl',
    draggable: 'draggable',
    drag: 'state-drag',
    tap: 'state-tap',
    active: 'active',
    tooltip: 'tooltip',
    pips: 'pips',
    pipsHorizontal: 'pips-horizontal',
    pipsVertical: 'pips-vertical',
    marker: 'marker',
    markerHorizontal: 'marker-horizontal',
    markerVertical: 'marker-vertical',
    markerNormal: 'marker-normal',
    markerLarge: 'marker-large',
    markerSub: 'marker-sub',
    value: 'value',
    valueHorizontal: 'value-horizontal',
    valueVertical: 'value-vertical',
    valueNormal: 'value-normal',
    valueLarge: 'value-large',
    valueSub: 'value-sub',
  };

  constructor() {}

  ngOnInit() {
    this.setSliderClass(this.value);
    if (this.cssClasses !== null) {
      this.sliderCssClasses = { ...this.sliderCssClasses, ...this.cssClasses };
    }
  }

  ngAfterViewInit(): void {
    // set unique aria-label on each slider handle
    let wingCalcSliderHandle = document.querySelector(
      '.crew-slider .noUi-handle'
    );
    let heatSliderHandle = document.querySelector(
      '.app-range-container .noUi-handle'
    );
    if (wingCalcSliderHandle) {
      wingCalcSliderHandle.setAttribute('aria-label', 'crew members');
    }
    if (heatSliderHandle) {
      heatSliderHandle.setAttribute('aria-label', 'levels of spiciness');
    }
  }

  onChange(value: any) {
    this.valueChanged.emit(value);
    this.setSliderClass(value);
  }

  onKeydown(e: any) {
    if (e.which === 37) {
      this.updateValue(-1, e);
    }

    if (e.which === 39) {
      this.updateValue(+1, e);
    }
  }

  setSliderClass(newValue: number) {
    if (this.colorfulHandle) {
      let bucket;
      if (newValue < 3) {
        bucket = 0;
      } else if (newValue < 8) {
        bucket = 1;
      } else {
        bucket = 2;
      }
      this.heatClass = 'spice-intensity-' + bucket;
    }
  }

  updateControlValue(control: UntypedFormControl, value: number, event: any) {
    let newValue = control.value + value;

    if (newValue < this.min || newValue > this.max) {
      return;
    }

    control.patchValue(newValue);
  }

  updateValue(value: number, event: any) {
    let newValue = this.value + value;

    if (newValue < this.min || newValue > this.max) {
      return;
    }

    this.value = newValue;
  }

  // This method is chiefly concerned with accidental ios zoom on interaction with component.
  preventZoom(event: any) {
    if (
      !event ||
      !event.touches ||
      !event.currentTarget ||
      !event.currentTarget.dataset
    ) {
      return;
    }

    let t2 = event.timeStamp;
    let t1 = event.currentTarget.dataset.lastTouch || t2;
    let dt = t2 - t1;
    let fingers = event.touches.length;
    event.currentTarget.dataset.lastTouch = t2;

    if (!dt || dt > 500 || fingers > 1) {
      return;
    }

    event.stopPropagation();
    event.preventDefault();
  }
}
