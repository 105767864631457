import { Injectable } from '@angular/core';

import { Menu } from '@wingstop/models/menu.model';
import { Category } from '@wingstop/models/menu/category.model';
import { CategoryProduct } from '@wingstop/models/menu/category-product.model';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor() {}

  findProductCategoryById(key: any, value: any, menu: Menu): Category {
    let product = null;

    if (menu && Array.isArray(menu.categories)) {
      product = this._findProductCategoryById(key, value, menu.categories);
    }

    return product;
  }

  private _findProductCategoryById(
    key: any,
    value: any,
    categories: Category[]
  ) {
    for (let i = 0; i < categories.length; i++) {
      if (
        categories[i].products &&
        categories[i].products.find((p) => p[key] === value)
      ) {
        return categories[i];
      } else if (
        categories[i].categories &&
        Array.isArray(categories[i].categories)
      ) {
        this._findProductCategoryById(key, value, categories[i].categories);
      }
    }
  }

  findProductByProperty(key: any, value: any, menu: Menu): CategoryProduct {
    let product = null;

    if (menu && Array.isArray(menu.categories)) {
      product = this._findProductByProperty(key, value, menu.categories);
    }

    return product;
  }
  _findProductByProperty(key: any, value: any, categories: Category[]) {
    for (let i = 0; i < categories.length; i++) {
      let product = null;
      if (
        categories[i].products &&
        (product = categories[i].products.find((p) => p[key] === value))
      ) {
        return product;
      } else if (
        categories[i].categories &&
        Array.isArray(categories[i].categories)
      ) {
        this._findProductByProperty(key, value, categories[i].categories);
      }
    }
  }

  private _findProductByMetadata(
    key: any,
    value: any,
    categories: Category[],
    matches?: CategoryProduct[]
  ) {
    if (matches === undefined) {
      matches = [];
    }

    for (let i = 0; i < categories.length; i++) {
      let products = [];
      if (
        categories[i].products &&
        (products = categories[i].products.filter((p) => {
          if (
            p.metadata &&
            p.metadata.find((m) => m.key === key && m.value === value)
          ) {
            return true;
          }
        }))
      ) {
        matches.push(...products);
      }
      if (categories[i].categories && Array.isArray(categories[i].categories)) {
        this._findProductByMetadata(
          key,
          value,
          categories[i].categories,
          matches
        );
      }
    }

    return matches;
  }

  findProductByMetadata(key: string, value: any, menu: Menu) {
    return this._findProductByMetadata(key, value, menu.categories);
  }

  public getFeatured(menu: Menu) {
    return this.findProductByMetadata('display', 'featured', menu);
  }

  public getSpecials(menu: Menu) {
    return this.findProductByMetadata('is-specials', 'true', menu);
  }
}
