<div class="spice-card">
  <div class="mb-3">
    <span class="svg-parent-green">
      <svg class="icon-flavor" [type]="iconName" alt=""></svg>
    </span>
    <h3>{{ name }}</h3>
    <div class="spice-panel" [attr.aria-label]="'Heat level ' + spiceLevel">
      <span
        [class]="'text-' + currentClass"
        *ngFor="let currentClass of spiceLevelCounter">
        <svg type="whats-hot" alt=""></svg>
      </span>
    </div>
    <p>{{ description }}</p>
  </div>
</div>
