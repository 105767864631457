<div *ngIf="shouldShow$ | async">
  <button
    #skipLink
    class="btn btn-primary"
    id="skip-link"
    (click)="skipToMain()">
    Skip to Main Content
  </button>

  <div
    aria-label="Mobile Navigation"
    class="border-bottom-shade nav-container mobile"
    [class.app-banner]="showAppBanner$ | async">
    <div class="nav-offer-container">
      <button
        aria-label="Open Mobile Navigation"
        aria-controls="mobile-nav-overlay"
        [attr.aria-expanded]="mobileMenuOpen"
        type="button"
        (click)="toggleMobileMenu()"
        class="nav-btn"
        id="mobile-hamburger">
        <svg type="hamburger"></svg>
      </button>
    </div>

    <div
      id="mobile-nav-overlay"
      [class.mobile-menu-open]="mobileMenuOpen"
      [class.app-banner]="showAppBanner$ | async">
      <div class="header">
        <svg type="user" aria-hidden="true"></svg>
        <div class="greeting">
          <p class="greeting_text h2">
            {{
            authentication ? 'Hi, ' + authentication?.firstname : 'Welcome'
            }}
          </p>
        </div>
        <button
          #closeMobileMenuBtn
          class="header_button__close"
          aria-label="Close Mobile Navigation"
          (click)="toggleMobileMenu()">
          <svg type="close"></svg>
        </button>
      </div>

      <wri-auth-navigation></wri-auth-navigation>

      <div class="link-container mobile">
        <a class="h4 menu-link" (click)="onCtaClick($event, menuPath)">Menu</a>

        <a class="h4 menu-link" href="{{ legacy_base_url }}/order" (click)="sendOrderGAEvent()">Locations</a>

        <div class="w-100 text-center mt-4">
          <wri-auth-navigation
            [showOnlyLogoutButton]="true"></wri-auth-navigation>
        </div>
      </div>
    </div>

    <div class="brand-container">
      <a [routerLink]="['/']">
        <img src="assets/images/wingstop-logo-green-340.png" alt="Wingstop" />
      </a>
    </div>

    <div class="account-container" [attr.aria-hidden]="mobileMenuOpen">
      <div class="mobile">
        <wri-active-offer></wri-active-offer>
      </div>
      <a
        [routerLink]="['/order']"
        aria-label="Find a Wingstop Restaurant Location">
        <svg type="location"></svg>
      </a>
      <div class="mobile">
        <wri-connected-bag-count></wri-connected-bag-count>
      </div>
    </div>
  </div>

  <div
    [class]="(careersConditionalClass || '') + ' ' + 'nav-container desktop'">
    <nav aria-label="Main" id="nav" class="constrain">
      <div class="left">
        <a href="{{ legacy_base_url }}" class="brand">
          <img
            src="assets/images/wingstop-logo-green-340.png"
            alt="Wingstop"
            data-cy="Home" />
        </a>
        <a
          class="h6 small menu-link"
          href="#"
          (click)="onCtaClick($event, menuPath)"
          [class.active]="currentUrl == '/menu'"
          data-cy="Menu">
          Menu
        </a>
        <a
          class="h6 small menu-link"
          href="{{ legacy_base_url }}/order"
          (click)="sendOrderGAEvent()"
          [class.active]="currentUrl == '/order'"
          data-cy="Locations">
          Locations
        </a>
      </div>
      <div class="right">
        <wri-cart-location></wri-cart-location>
        <wri-active-offer></wri-active-offer>
        <div class="dropdown">
          <button
            id="user-menu-desktop"
            (click)="onCtaClick($event, 'account-popup')"
            data-toggle="dropdown"
            aria-label="User pop up menu, open for log in and sign up options"
            class="pointer"
            aria-haspopup="true"
            aria-expanded="false">
            <svg type="user" alt=""></svg>
          </button>
          <div class="dropdown-menu" aria-labelledby="user-menu-desktop">
            <wri-auth-navigation></wri-auth-navigation>
          </div>
        </div>
        <wri-connected-bag-count></wri-connected-bag-count>
      </div>
    </nav>
  </div>
</div>
<ng-template *ngIf="kiosk && !digitalMenu">
  <div class="kiosk-mode">
    <div class="col-8 col-sm-5 col-md-3 text-center">
      <img
        class="logo"
        src="assets/images/wingstop-logo-green-340.png"
        alt="Wingstop" />
    </div>
  </div>
</ng-template>
<div id="global_messaging_navigation"></div>
