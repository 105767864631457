import { environment as base } from '@wingstop/environments/environment.dev-qa';

export const environment = {
  ...base,
  production: true,
  envName: 'dev-hotfix',

  ecomm: {
    baseUrl: 'https://ecomm.hotfix.us.regional.dev.cf.10piececombo.com',
    menuUrl: 'https://ecomm.hotfix.us.regional.dev.cf.10piececombo.com',
    webSocketUrl: 'wss://websocket.hotfix.us.regional.dev.cf.10piececombo.com',
  },
};
