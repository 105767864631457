import { Flavor } from '@wingstop/models/cms/flavor.model';

export const environment = {
  production: false,
  envName: 'local',
  devToolsEnabled: true,

  apiBaseUrl: 'https://dev-api.wingstop.com',
  englishSiteUrl: 'https://dev.wingstop.com/',
  spanishSiteUrl: 'https://dev-es.wingstop.com/',
  apiBaseUrlMultiPort: false,
  enableBirthdayModal: false,
  enableDeleteAccount: false,
  pciProxyJs:
    'https://pay.sandbox.datatrans.com/upp/payment/js/datatrans-inline-1.0.0.min.js',
  pciProxyMerchantId: '1100016502',

  fiservUcomJs:
    'https://int.api.firstdata.com/ucom/v2/static/v2/js/ucom-sdk.js',
  fiservMerchantId: '1111',
  fiservApiKey: 'eKPIX1bgGQGR1jkYDKO556bh9TYGAOhG',

  applePayMerchantIdentifier: 'merchant.com.wingstop.prelive',

  geocodeProvider: 'mapbox',
  geocodeThreshold: 0.9,
  geocodeFallback: 'google',

  gmapKey: 'AIzaSyCz7GW52B5Q4WD5Rd2suvEgWikLTH0CSHQ',
  gmapStaticApi: '//maps.googleapis.com/maps/api/staticmap',
  gmapGeocodeApi: 'https://maps.googleapis.com/maps/api/geocode/json',
  recaptchaKey: '6Le_mVwUAAAAAO684sFDdewsQg7Ly7hB6SNlWSl9',

  validateBasket: true,
  dynamicCalories: false,

  hiddenLocationExtRefs: <string[]>[],

  //wing calculator
  wingCalculatorExplicitMapping: true,

  // google optimize
  googleOptimizeId: 'OPT-WFHXSNH',

  // Social
  googleOauthClientId:
    '132749923255-pselncufrk5la62ncns65a63vlrg1phi.apps.googleusercontent.com',
  facebookAppId: '517887671946807',

  globalMenuId: 60819,
  limitDispatchAttemptStores: 3,

  orderWindow: { lower: -2, upper: +3 }, // for ASAP order 'your order will be ready in ...'
  showUnavailableProductMessage: false,

  emptyProductImage: '/assets/images/logo-green-product.png',
  emptyCategoryImage: '/assets/images/logo-green-category.png',

  productImageId: 'mobile-webapp-menu',
  categoryImageId: 'mobile-webapp-customize',

  hungerLevelMapping: [
    {
      level: 'snacky',
      index: 0,
    },
    {
      level: 'hungry',
      index: 1,
    },
    {
      level: 'starving',
      index: 2,
    },
  ],

  clubMessage:
    'If you are signed up for our email club, you may not have an order ahead account. ' +
    'If you have trouble logging in with your email, try creating a new account.',
  clubTitle: 'E-Club Account',
  buttonLabel: 'GOT IT',

  cancelTitle: 'Cancel Existing Order?',
  cancelDescription:
    'You have an order in progress. Would you like to keep or discard your current order?',
  continueCancel: 'Keep',
  discardCancel: 'Discard',

  modifyTitle: 'Modify Existing Order?',
  modifyDescription:
    'Modifying your order will clear your current basket. Are you sure you want to modify this order?',
  continueModify: 'Modify',
  discardModify: 'Nevermind, I changed my mind',

  deleteFavoriteTitle: 'Are you sure?',
  deleteFavoriteDescription:
    'Deleting this item will permanently remove it from your favorites list.',
  continueDelete: 'Remove',
  discardDelete: 'Nevermind, I changed my mind',

  addFavoriteTitle: 'Favorite order',
  addFavoriteDescription: 'Choose a name for your favorite order',
  baseAboutSection:
    '<p>There’s nothing better than chicken wings straight to your doorstep, especially when a [STATE]' +
    ' Wingstop perfects your order through our order-ahead experience. Can’t wait? Have your made-to-order wings ready ' +
    'and hot right when you walk in our doors. Customize your order by choosing from our 11 signature flavors. From ' +
    'legendary Lemon Pepper, to the Buffalo style Original Hot or the bold, rich sauce of Hickory Smoked BBQ, our' +
    ' iconic flavors are always made-to-order and sauce and tossed to perfection. You can keep it savory, sweet, ' +
    'spicy or all the above at your [STATE] Wingstop locations.</p>' +
    '<p>The first franchised Wingstop location opened in 1997, and by 2002 we had served the world one billion wings.' +
    ' It’s flavor that defines us and has made Wingstop one of the fastest growing restaurant brands. Wingstop is proud to serve up flavor in [STATE].</p>' +
    '<p>Wingstop is the destination when you crave freshly-made wings, hand-cut seasoned fries and any of our famous ' +
    "sides like Cajun Fried Corn or Buffalo Ranch Fries. For people who demand flavor in everything they do, there's" +
    " only Wingstop - because it's more than a meal, it's a flavor experience.</p><b> Wingstop, Where Flavor Gets Its Wings™</b>",
  cityAboutSection:
    '<p>At Wingstop in [CITY], our devoted Wing Experts don’t mess around. Every order is ensured to be drippin’ in flavor,' +
    ' and all wings are hot and made-to-order with a variety of dips for dunkin’. Pair your wings with our famous, made-from-scratch ranch dipping sauce,' +
    ' bleu cheese and more. With 11 signature flavors – from our legendary Lemon Pepper to the sweetened up heat of Mango Habanero – you have the option of' +
    ' boneless or classic wings, as well as tenders, all sauced and tossed and available with hand-cut, seasoned fries and our other signature sides like ' +
    'Cajun' +
    ' Fried Corn or Buffalo Ranch Fries.' +
    '</p>' +
    '<p>At Wingstop in [CITY], getting hot, freshly-made and flavorful wings is as easy as a few clicks. Place a carryout order at the Wingstop nearest you, ' +
    'or get it delivered straight to your doorstep at participating delivery restaurants.\n' +
    '</p>' +
    '<p>For [CITY] chicken wings that satisfy the crave, choose Wingstop.\n' +
    '</p>' +
    '<b>Wingstop, Where Flavor Gets Its Wings™</b>',
  continueAdding: 'Done',
  discardAdding: 'Nevermind, I changed my mind',

  locationAvailabilityTitle: 'Error',
  locationAvailabilityDescription:
    'Sorry, the menu is not available for this location now',
  locationAvailabilityButton: 'Got it',

  deliveryTimeDescription:
    ' Please choose another delivery time. Do you want continue without this product?',

  carryoutTimeDescription: ' Do you want continue without this product?',

  locationSearchPlaceholder: 'Search Zip or City, State',
  locationSearchPlaceholderCarryout: 'Search Zip or City, State',
  locationSearchPlaceholderDelivery: 'Enter Your Delivery Address',
  locationSearchDebounce: 1500,
  locationSearchMinimumCharacters: 3,
  locationPageAboutSection:
    '<p>When you’re craving insane flavor and customizable wings, [Store] is the place to go in [City], [State]. With over 11 iconic' +
    ' flavors, our cooked-to-order wings will satisfy any craving. From Lemon Pepper to Original Hot, our savory flavors will leave you ' +
    'satisfied after every order.</p>' +
    '<p>At [Store], getting wings exactly how you like em’ is seamless. Order online for carryout and delivery from [Store] ' +
    'to get your hands on our classic or boneless wings as well as our tenders. For Buffalo wings, try our Original Hot or Mild flavors. Hungry for ' +
    'a signature classic? You’ll love our legendary Lemon Pepper or Louisiana Rub. Whatever flavors you like, pair them with our hand-cut,' +
    ' seasoned fries and ' +
    'other signature sides like Cajun Fried Corn or Buffalo Ranch Fries.</p>' +
    "<p>Since setting up shop in '94, we've been putting flavor first. And we're not about to stop. Our proprietary recipes," +
    ' outstanding food and superior guest experience created a demand that could only be satisfied by more locations. In 1997, we began offering franchises, ' +
    'and today we are one of the fastest-growing concepts in the country, attracting top entrepreneurs, operators and brand partners with the drive and ' +
    'passion to own their own business.</p> <b>Wingstop, Where Flavor Gets Its Wings™</b>',

  clubFormGender: ['Male', 'Female'],
  clubFormRadioButtons: [
    {
      label: 'Update Preferences',
      value: 'sub_add_update',
      id: 'preferences',
      state: true,
    },
    {
      label: 'Unsubscribe',
      value: 'unsub',
      id: 'unsubscribe',
      state: false,
    },
  ],
  stronglySuggestCurbside: true,
  states: {
    AL: 'Alabama',
    AK: 'Alaska',
    AS: 'American Samoa',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District Of Columbia',
    FM: 'Federated States Of Micronesia',
    FL: 'Florida',
    GA: 'Georgia',
    GU: 'Guam',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MH: 'Marshall Islands',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    MP: 'Northern Mariana Islands',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PW: 'Palau',
    PA: 'Pennsylvania',
    PR: 'Puerto Rico',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VI: 'Virgin Islands',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
  },

  tipShowNone: false,
  tipAmountDollars: [3, 4, 5, 6],
  tipAmountDollarsPickup: [0, 3, 4, 5],
  tipAmountPercents: [15, 18, 20, 25],
  tipAmountPercentsPickup: [0, 15, 18, 20],
  tipThresholdEnabled: true,
  tipThreshold: 20,
  tipIndexDefault: 1,
  tipIndexDefaultPickup: 0,
  tipMaxAmount: 999.99,
  tipAuto: false,
  tipResetOnThresholdChange: true,
  tipActAsRadio: true,

  // Flavors
  flavorsGetDataFromApi: true,
  //flavors: <Flavor[]>[],
  flavors: <Flavor[]>[
    new Flavor({
      id: '6d83f4ff-1043-4b2b-9f72-88e2d081dc20',
      name: 'Atomic',
      ref: 'atomic',
      iconName: 'flavor-atomic',
      description: `It's the hottest we've got. Find out for yourself.`,
      spiceLevel: 5,
      flavorType: 'wet',
      backgroundImage: 'assets/images/flavors/flavor-card-bg-4.jpg',
      offer: '',
      createdAt: '2020-10-09 00:13:15',
      updatedAt: '2020-10-09 00:13:15',
      isActive: true,
      weight: 1,
    }),
    new Flavor({
      id: 'b23f1bb7-9c1e-4867-8256-1ba810e7a1c0',
      name: 'Mango Habanero',
      ref: 'mango-habanero',
      iconName: 'flavor-mango-habanero',
      description: `It's sweet, with heat. And then some. Seriously.`,
      spiceLevel: 4,
      flavorType: 'wet',
      backgroundImage: 'assets/images/flavors/flavor-card-bg-1.jpg',
      offer: '',
      createdAt: '2020-10-09 00:13:15',
      updatedAt: '2020-10-09 00:13:15',
      isActive: true,
      weight: 2,
    }),
    new Flavor({
      id: '7443ef4e-797b-4ffa-b683-ca9b8f627d05',
      name: 'Cajun',
      ref: 'cajun',
      iconName: 'flavor-cajun',
      description:
        'Tossed in Original Hot, dusted with bold and zesty Cajun seasoning.',
      spiceLevel: 4,
      flavorType: 'wet',
      backgroundImage: 'assets/images/flavors/flavor-card-bg-4.jpg',
      offer: '',
      createdAt: '2020-10-09 00:13:15',
      updatedAt: '2020-10-09 00:13:15',
      isActive: true,
      weight: 3,
    }),
    new Flavor({
      id: '53698308-2cde-4667-88f8-630ffa47f1ba',
      name: 'Spicy Korean Q',
      ref: 'spicy-korean-q',
      iconName: 'flavor-spicy-korean-q',
      description:
        'Ginger, garlic, sriracha, and crushed red pepper create the perfect balance of sweet and spicy.',
      spiceLevel: 3,
      flavorType: 'wet',
      backgroundImage: 'assets/images/flavors/flavor-card-bg-5.jpg',
      offer: '',
      createdAt: '2020-10-09 00:13:15',
      updatedAt: '2020-10-09 00:13:15',
      isActive: true,
      weight: 4,
    }),
    new Flavor({
      id: '10a83eac-7447-4437-8e76-1c37654a01d3',
      name: 'Louisiana Rub',
      ref: 'lousiana-rub',
      iconName: 'flavor-louisiana-rub',
      description: 'A crispy, spiced dry rub, with a distinctly Cajun drawl.',
      spiceLevel: 3,
      flavorType: 'dry',
      backgroundImage: 'assets/images/flavors/flavor-card-bg-6.jpg',
      offer: '',
      createdAt: '2020-10-09 00:13:15',
      updatedAt: '2020-10-09 00:13:15',
      isActive: true,
      weight: 5,
    }),
    new Flavor({
      id: 'a4c93427-1947-4599-9260-813be8c0f39e',
      name: 'Mild',
      ref: 'mild',
      iconName: 'flavor-mild',
      description: 'That O.G. hot, turned down a notch.',
      spiceLevel: 2,
      flavorType: 'wet',
      backgroundImage: 'assets/images/flavors/flavor-card-bg-2.jpg',
      offer: '',
      createdAt: '2020-10-09 00:13:15',
      updatedAt: '2020-10-09 00:13:15',
      isActive: true,
      weight: 6,
    }),
    new Flavor({
      id: '4e164daa-39d6-4862-ba09-e82d3873d75c',
      name: 'Original Hot',
      ref: 'original-hot',
      iconName: 'flavor-original-hot',
      description: `The first sauce we tossed. Full of heat and tang. It's called original for a reason.`,
      spiceLevel: 3,
      flavorType: 'wet',
      backgroundImage: 'assets/images/flavors/flavor-card-bg-5.jpg',
      offer: 'Most Popular',
      createdAt: '2020-10-09 00:13:15',
      updatedAt: '2020-10-09 00:13:15',
      isActive: true,
      weight: 7,
    }),
    new Flavor({
      id: 'dfcd0642-28c7-4999-b050-e3ab93c41ea6',
      name: 'Hickory Smoked BBQ',
      ref: 'hickory-smoked-bbq',
      iconName: 'flavor-hickory-smoked-bbq',
      description:
        "Bold. Smoky. Sweet. Rich. It's everything you love about BBQ, but better.",
      spiceLevel: 2,
      flavorType: 'wet',
      backgroundImage: 'assets/images/flavors/flavor-card-bg-2.jpg',
      offer: 'Most Popular',
      createdAt: '2020-10-09 00:13:15',
      updatedAt: '2020-10-09 00:13:15',
      isActive: true,
      weight: 8,
    }),
    new Flavor({
      id: '38b12f89-95ae-4bd4-ab15-d0c9ea1449a1',
      name: 'Garlic Parmesan',
      ref: 'garlic-parmesan',
      iconName: 'flavor-garlic-parmesan',
      description:
        'Savory garlic, buttery parmesan. This dry rub is simple, potent, and delicious.',
      spiceLevel: 0,
      flavorType: 'dry',
      backgroundImage: 'assets/images/flavors/flavor-card-bg-4.jpg',
      offer: '',
      createdAt: '2020-10-09 00:13:15',
      updatedAt: '2020-10-09 00:13:15',
      isActive: true,
      weight: 9,
    }),
    new Flavor({
      id: 'a573de3b-3801-4d32-a0f9-8285e6adf7b3',
      name: 'Hawaiian',
      ref: 'hawaiian',
      iconName: 'flavor-hawaiian',
      description:
        'A sweet and tangy blend of island citrus balanced with rich Asian flavors.',
      spiceLevel: 0,
      flavorType: 'wet',
      backgroundImage: 'assets/images/flavors/flavor-card-bg-5.jpg',
      offer: '',
      createdAt: '2020-10-09 00:13:15',
      updatedAt: '2020-10-09 00:13:15',
      isActive: true,
      weight: 10,
    }),
    new Flavor({
      id: '170ed00e-8be5-4bda-b7b0-880f3cbdab13',
      name: 'Lemon Pepper',
      ref: 'lemon-pepper',
      iconName: 'flavor-lemon-pepper',
      description:
        'Zesty lemon. Cracked black pepper. This dry rub is the ultimate gateway flavor.',
      spiceLevel: 1,
      flavorType: 'dry',
      backgroundImage: 'assets/images/flavors/flavor-card-bg-6.jpg',
      offer: 'Most Popular',
      createdAt: '2020-10-09 00:13:15',
      updatedAt: '2020-10-09 00:13:15',
      isActive: false,
      weight: 0,
    }),
  ],

  autoFormatCurrencyInput: true,
  gtagScript: `
     <!-- Google Tag Manager -->
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-T4RLN6RS');
    <!-- End Google Tag Manager -->`,
  gtagHelper: `
  <!-- Google Tag Manager (noscript) -->
  <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T4RLN6RS"
                    height="0" width="0" style="display:none;visibility:hidden" aria-hidden="true"></iframe>
  <!-- End Google Tag Manager (noscript) -->`,

  // OneTrust privacy scripts
  oneTrustPrivacyId: 'otprivacy-notice-script',
  oneTrustPrivacySource:
    'https://privacyportal-eu-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js',
  oneTrustPrivacyScript: `
    window.checkExist = setInterval(function() {
      if (window.OneTrust && window.OneTrust.NoticeApi && window.OneTrust.NoticeApi.Initialized) {
        clearInterval(checkExist);
        OneTrust.NoticeApi.Initialized.then(function() {
          OneTrust.NoticeApi.LoadNotices(["https://privacyportal-eu-cdn.onetrust.com/607a2afe-aa1a-4dfd-8b63-a64b4d563981/privacy-notices/draft/573de22f-eb1a-4eaa-b365-01508b2c9a54.json"], false);
        });
      }
    }, 100);
  `,
  oneTrustPrivacySettings: '',
  // OneTrust Terms of Use scripts
  oneTrustTermsId: 'otprivacy-notice-script',
  oneTrustTermsSource:
    'https://privacyportal-eu-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js',
  oneTrustTermsScript: `
    window.checkExist = setInterval(function() {
      if (window.OneTrust && window.OneTrust.NoticeApi && window.OneTrust.NoticeApi.Initialized) {
        clearInterval(checkExist);
        OneTrust.NoticeApi.Initialized.then(function() {
          OneTrust.NoticeApi.LoadNotices(["https://privacyportal-eu-cdn.onetrust.com/607a2afe-aa1a-4dfd-8b63-a64b4d563981/privacy-notices/draft/6f038a3c-8051-4725-93c9-ee9fc18e5755.json"], false);
        });
      }
    }, 100);
  `,
  oneTrustTermsSettings: '',
  // California Consumer Privacy Policy scripts
  caliConsumerPrivacyId: 'otprivacy-notice-script',
  caliConsumerPrivacySource:
    'https://privacyportal-eu-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js',
  caliConsumerPrivacyScript: `
    window.checkExist = setInterval(function() {
      if (window.OneTrust && window.OneTrust.NoticeApi && window.OneTrust.NoticeApi.Initialized) {
        clearInterval(checkExist);
        OneTrust.NoticeApi.Initialized.then(function() {
          OneTrust.NoticeApi.LoadNotices(["https://privacyportal-eu-cdn.onetrust.com/607a2afe-aa1a-4dfd-8b63-a64b4d563981/privacy-notices/draft/16fe1a38-4e67-44f9-9f22-cba80c8b038b.json"], false);
        });
      }
    }, 100);
  `,
  caliConsumerPrivacySettings: '',
  // California Business Contacts Privacy Notice scripts
  caliBusinessContactsPrivacyId: 'otprivacy-notice-script',
  caliBusinessContactsPrivacySource:
    'https://privacyportal-eu-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js',
  caliBusinessContactsPrivacyScript: `
    window.checkExist = setInterval(function() {
      if (window.OneTrust && window.OneTrust.NoticeApi && window.OneTrust.NoticeApi.Initialized) {
        clearInterval(checkExist);
        OneTrust.NoticeApi.Initialized.then(function() {
          OneTrust.NoticeApi.LoadNotices(["https://privacyportal-eu-cdn.onetrust.com/607a2afe-aa1a-4dfd-8b63-a64b4d563981/privacy-notices/draft/2d4832aa-0b0b-42a2-a278-fa54bd573464.json"], false);
        });
      }
    }, 100);
  `,
  caliBusinessContactsPrivacySettings: '',
  // California Job Applicant Notice at Collection scripts
  caliJobApplicantNoticePrivacyId: 'otprivacy-notice-script',
  caliJobApplicantNoticePrivacySource:
    'https://privacyportal-eu-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js',
  caliJobApplicantNoticePrivacyScript: `
    window.checkExist = setInterval(function() {
      if (window.OneTrust && window.OneTrust.NoticeApi && window.OneTrust.NoticeApi.Initialized) {
        clearInterval(checkExist);
        OneTrust.NoticeApi.Initialized.then(function() {
          OneTrust.NoticeApi.LoadNotices(["https://privacyportal-eu-cdn.onetrust.com/607a2afe-aa1a-4dfd-8b63-a64b4d563981/privacy-notices/draft/fb6581b4-543e-4150-a38d-250d56d10bd1.json"], false);
        });
      }
    }, 100);
  `,
  caliJobApplicantNoticePrivacySettings: '',
  interactionStudioDataEnabled: true,
  epsilonRTI: 'https://s-usweb.dotomi.com/renderer/spd/global/spd.js',

  applePay: {
    merchantId: 'merchant.com.wingstop.applepay.test',
  },
  pilotUrl: 'https://demo.sit.ordering.wingstop.com',
  pilotUrl2: 'https://demo-hotfix.sit.ordering.wingstop.com',
  pilotUrl3: 'https://demo-hotfix.sit.ordering.wingstop.com',
  hostname2: 'dev2.wingstop.com',
  hostname3: 'dev2.wingstop.com',
  pingAuthCookieName: 'pingAuth',
  pingProfileCookieName: 'pingAuthProfile',
  pingCookieDomain: 'wingstop.com',

  ecomm: {
    baseUrl: 'https://ecomm.env1.us.regional.sit.cf.10piececombo.com',
    menuUrl: 'https://ecomm.env1.us.regional.sit.cf.10piececombo.com',
    webSocketUrl: 'wss://websocket.env1.us.regional.sit.cf.10piececombo.com',
  },
  featureFlags: {
    enableAddOns: true,
    enableApplePay: true,
    enableCartRecommendations: true,
    enableMenuRecommendations: true,
    enableTip: true,
    enableDonation: true,
    enableLogInNGFE: true,
    enableDevLogs: true,
    enableActiveOffer: true,
    enableVaultedGiftCard: true,
    enableOrderHistory: true,
  },
  fiserv: {
    apiKey: 'eKPIX1bgGQGR1jkYDKO556bh9TYGAOhG',
    env: 'int',
    sdkUri: 'https://int.api.firstdata.com/ucom/v2/static/v2/js/ucom-sdk.js',
  },
  grecaptcha: {
    siteKey: '6LdRwzImAAAAALRSzuLlYWC-n19EnQLfruyKARIp',
  },
  legacy: {
    webAppBaseUrl: 'https://dev.wingstop.com',
  },
  ping: {
    clientId: '5f70cbfc-0d35-4a14-b2b1-b80986d92ebe',
    clientScope: 'openid ecomm_api',
    stsAuthority: 'https://nplogin.wingstop.com/as',
  },
  smfc: {
    url: 'https://cdn.evgnet.com/beacon/wingstoprestaurants/developmentngfe/scripts/evergage.min.js',
  },
};
