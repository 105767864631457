import {
  PreloadAllModules,
  RouterModule,
  Routes,
  UrlSegment,
} from '@angular/router';

import { LegacyRedirectGuard } from '@wingstop-inc/ngfe-web';
import { NgModule } from '@angular/core';

const routes: Routes = [
  {
    path: '',
    canActivate: [LegacyRedirectGuard],
    loadChildren: () =>
      import('./modules/welcome/welcome.module').then((m) => m.WelcomeModule),
  },
  {
    matcher: thighstopMatcher,
    redirectTo: 'menu',
  },
  {
    path: 'location',
    loadChildren: () =>
      import('@wingstop-inc/ngfe-web').then((m) => m.LocationMenuModule),
  },
  {
    path: 'locations',
    loadChildren: () =>
      import('@wingstop-inc/ngfe-web').then((m) => m.LocationMenuModule),
  },
  {
    path: 'order',
    loadChildren: () =>
      import('@wingstop-inc/ngfe-web').then((m) => m.OrderModule),
  },
  {
    path: 'account',
    loadChildren: () =>
      import('@wingstop-inc/ngfe-web').then((m) => m.AccountsModule),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('@wingstop-inc/ngfe-web').then((m) => m.AccountsModule),
  },
  {
    path: 'menu',
    loadChildren: () =>
      import('@wingstop-inc/ngfe-web').then((m) => m.GlobalMenuModule),
  },
  {
    path: 'careers',
    loadChildren: () =>
      import('./modules/careers/careers.module').then((m) => m.CareersModule),
  },
  {
    path: 'flavors',
    loadChildren: () =>
      import('./modules/flavors/flavors.module').then((m) => m.FlavorsModule),
  },
  {
    path: 'privacy',
    loadChildren: () =>
      import('./modules/privacy/privacy.module').then((m) => m.PrivacyModule),
  },
  {
    path: 'california-consumer-privacy-act-privacy-policy',
    loadChildren: () =>
      import('./modules/privacy/privacy.module').then((m) => m.PrivacyModule),
    data: { privacy_type: 'caliConsumerPrivacy' },
  },
  {
    path: 'california-business-contact-privacy-notice',
    loadChildren: () =>
      import('./modules/privacy/privacy.module').then((m) => m.PrivacyModule),
    data: { privacy_type: 'caliBusinessContactsPrivacy' },
  },
  {
    path: 'california-job-applicant-notice-at-collection',
    loadChildren: () =>
      import('./modules/privacy/privacy.module').then((m) => m.PrivacyModule),
    data: { privacy_type: 'caliJobApplicantNoticePrivacy' },
  },
  {
    path: 'terms-of-use',
    loadChildren: () =>
      import('./modules/terms/terms.module').then((m) => m.TermsModule),
  },
  {
    path: 'door-dash-opt-out',
    loadChildren: () =>
      import('./modules/door-dash/door-dash.module').then(
        (m) => m.DoorDashModule
      ),
  },
  {
    path: 'job-search',
    loadComponent: () =>
      import('@wingstop/order/choose-location/choose-location.component').then(
        (m) => m.ChooseLocationComponent
      ),
  },
  {
    path: '**',
    canActivate: [LegacyRedirectGuard],
    loadChildren: () =>
      import('./modules/global/global.module').then((m) => m.GlobalModule),
  },
];

export function thighstopMatcher(segments: UrlSegment[]) {
  return segments.length >= 2 &&
    segments[0].path.match(/location/) &&
    segments[1].path.match(/thighstop/g)
    ? { consumed: segments }
    : null;
}

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'ignore',
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRouting {}
