import { Injectable } from '@angular/core';
import { State } from '@ngrx/store';
import { configureStore, IAppStore } from '@wingstop/store/app-store';
import { AppStateActions } from '@wingstop/store/app/app-state.actions';

@Injectable()
export class ReduxProvider {
  constructor(
    private ngRedux: State<IAppStore>,
    private appStateActions: AppStateActions
  ) {}

  public load(): Promise<any> {
    return new Promise((resolve, reject) => {
      configureStore().then((store) => {
        const resolvePromise = () => {
          resolve(store);
        };

        this.appStateActions.setCmsFeatureFlags();

        // this.ngRedux.provideStore(store);
        this.appStateActions.getGlobalMenu();

        // Resync the basket if possible
        this.appStateActions.refreshAuthentication().then((a) => {
          this.appStateActions.refreshBasket().then(resolvePromise);
        });
      });
    });
  }
}
