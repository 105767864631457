<div class="coke-freestyle" tabindex="0">
  <img
    src="assets/images/coke-freestyle.png"
    alt="Coke Freestyle Icon"
    aria-hidden="true"
    class="coke-freestyle__icon"
    [ngStyle]="iconSize && { width: iconSize }" />
  <p class="coke-freestyle__message" [ngStyle]="labelStyle">
    {{ message }}
  </p>
</div>
