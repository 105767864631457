import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '@wingstop/services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { State } from '@wingstop/models/location/location-state.model';
import { environment } from '@wingstop/environments/environment';
import { City } from '@wingstop/models/location/location-city.model';
import { Location } from '@wingstop/models/location/location.model';
import { UserDeliveryAddress } from '@wingstop/models/login/user-delivery-address.model';
import { GlobalService } from '@wingstop/services/global.services';
import { Basket } from '@wingstop/models/basket.model';
import { AppStateActions } from '@wingstop/store/app/app-state.actions';
import { ProductService } from '@wingstop/services/product.service';
import { State as ReduxState } from '@ngrx/store';
import { IAppStore } from '@wingstop/store/app-store';
import { Menu } from '@wingstop/models/menu.model';
import { CategoryProduct } from '@wingstop/models/menu/category-product.model';
import {
  faFacebookF,
  faInstagram,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { Observable, Subscription } from 'rxjs';
import { AppStateSelectors } from '@wingstop/store/app/app-state.selectors';
import { SeoService } from '@wingstop/services/seo.service';
import { LatLngLiteral } from 'ngx-google-places-autocomplete/objects/latLng';
import { DOCUMENT } from '@angular/common';
import { GoogleMapProviderService } from 'src/app/providers/google-map-provider.service';

@Component({
  selector: 'app-location-state',
  templateUrl: './location-pages.component.html',
  styleUrls: ['./location-pages.component.scss'],
})
export class LocationPagesComponent implements OnInit {
  @ViewChild('slickModal') slickModal: any;

  @Input() stateData: State;
  @Input() cityData: City;
  @Input() locationData: Location;
  public showState: boolean = false;
  public showCity: boolean = false;
  public showDetails: boolean = false;
  public currentState: string;
  public routeParams: any;
  public basketAttemptCount: number;
  public product: string;
  public basket: Basket;
  public menu: Menu;
  public errorCannotDeliverToThatAddress: boolean;
  public loading: boolean;
  public loadingPromise: any;
  public selectButtonCallback: Function;
  public specialsSliderConfig: any;
  public spiceSliderConfig: any = null;
  public nearbyLocations: any[] = [];
  public favLocations: Location[];
  public saved: boolean = false;
  public nomnomData: any = {};
  public canDeliver: boolean;
  public canCarryout: boolean;
  public slickModalPlaying = true;
  public activeHours: string = 'carryout';
  private subscriptions: Subscription[] = [];
  private specials: CategoryProduct[];
  icons = {
    faFacebookF,
    faInstagram,
    faTwitter,
  };
  public showCokeFreestyle: boolean = false;
  public cokeFreestyleEnabledInCms: boolean;
  public locationPagesBusy: Promise<any>;
  public gMapApiLoaded$: Observable<boolean>;
  constructor(
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private appStateActions: AppStateActions,
    private productService: ProductService,
    private seoService: SeoService,
    private appStateSelectors: AppStateSelectors,
    private appStore: ReduxState<IAppStore>,
    private googleMapProviderService: GoogleMapProviderService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        if (Object.keys(params).length > 0) {
          this.routeParams = params;
          this.currentState = this.router.url.split('/')[1].toLowerCase();
          if (params['location']) {
            this.getCity(this.currentState, params['city'], true);
          } else {
            this.getCity(this.currentState, params['city']);
          }
        } else {
          this.currentState = this.router.url.replace('/', '').toLowerCase();
          this.getState(this.currentState);
        }
      })
    );

    this.subscriptions.push(
      this.appStateSelectors.basket.subscribe((value) => {
        this.basket = value;
      })
    );

    // subscribe to changes in the favorite locations
    this.subscriptions.push(
      this.appStateSelectors.favoriteLocations.subscribe((favLocations) => {
        this.favLocations = favLocations; // when a change occurs, save it
      })
    );
    this.selectButtonCallback = this.makeBasketAt.bind(this);

    this.subscriptions.push(
      this.appStateSelectors.showCokeFreestyle.subscribe((result) => {
        this.cokeFreestyleEnabledInCms = result;
      })
    );
  }

  public ngAfterContentInit() {
    // Handle loading and showing the map
    this.gMapApiLoaded$ = this.googleMapProviderService.initMap();
  }

  // Functionality to get the selected state
  public getState(location: string) {
    this.apiService
      .getStates(location)
      .then((state) => {
        this.stateData = state;
      })
      .then(() => {
        this.seoService.setMetaData({
          title:
            this.stateData.name +
            ' - Information - Wings Restaurant | Wingstop',
          description:
            'View all Wingstop wing locations in ' + this.stateData.name,
        });
        this.createSchemaData(this.stateData);
      })
      .then(() => {
        this.showState = true;
      });
  }

  // Functionality to get the selected city and location details if needed
  public getCity(state: string, cityName: string, locationDetails = false) {
    const stateAbbreviation = this.getKeyByValue(environment.states, state);
    this.apiService
      .getCities(stateAbbreviation, cityName)
      .then((city) => {
        city['stateName'] = environment.states[city['stateAbbreviation']];
        this.cityData = city;
        this.cityData.restaurants = this.cityData.restaurants.map((cv: any) => {
          let restaurant = new Location(cv);
          this.canDeliver = restaurant.canDeliver();
          this.canCarryout = restaurant.canCarryout();
          restaurant.initializeHours(this.apiService);
          return restaurant;
        });
        this.nomnomData = city.restaurants[0].nomnom;
        this.seoService.setMetaData({
          title: city.name + ' - Information - Wings Restaurant | Wingstop',
          description:
            'View all Wingstop  wing locations in ' +
            city.name +
            ',' +
            city.stateName,
        });
        this.createSchemaData(null, city, null);
      })
      .then((data) => {
        if (!locationDetails) {
          this.showCity = true;
        } else {
          this.getLocationDetails(this.cityData.restaurants);
        }
      });
  }

  // Function to get the location details
  public getLocationDetails(restaurants: []) {
    this.locationPagesBusy = new Promise((resolve, reject) => {
      this.apiService
        .geocodeSearch(
          `${this.cityData.name}, ${this.cityData.stateAbbreviation}`
        )
        .toPromise()
        .then((geocodeResponse) => {
          this.apiService
            .search(geocodeResponse[0].latitude, geocodeResponse[0].longitude)
            .then((locations) => {
              // Check if geocode response location matches the location slug
              let result = locations.filter((location) => {
                return location.slug === this.routeParams['location'];
              });
              if (result.length > 0) {
                let wantedLocation = result[0];
                this.locationData = wantedLocation;
                this.canCarryout = wantedLocation.canCarryout();
                this.canDeliver = wantedLocation.canDeliver();
                this.seoService.setMetaData({
                  title:
                    wantedLocation.name +
                    ',' +
                    wantedLocation.state +
                    ' - Information - Wings Restaurant | Wingstop',
                  description:
                    'Start your online order at ' +
                    wantedLocation.name +
                    ',' +
                    wantedLocation.city +
                    ',' +
                    wantedLocation.state,
                });
                this.apiService.menu(wantedLocation.id).then((menu) => {
                  this.menu = menu;
                  this.specials =
                    this.menu.getProductsByCategorySlug('specials');
                  this.scheduleCarouselSetup();
                  this.locationData
                    .initializeHours(this.apiService)
                    .then(() => {
                      this.handleStoreFeatures();
                    });
                  this.createSchemaData(null, null, wantedLocation);
                  resolve(null);
                });
              } else {
                // Check if any of the city data restaurants match the location slug
                const selectedRestaurant = restaurants.find((restaurant) => {
                  return restaurant['slug'] === this.routeParams['location'];
                });
                if (!selectedRestaurant) {
                  resolve(null);
                  // If a specific restaurant hasn't been matched, navigate to city/state page (i.e., no location data)
                  return this.router.navigateByUrl(
                    `/${this.currentState}/${this.routeParams['city']}`
                  );
                }
                this.apiService
                  .location(selectedRestaurant['id'])
                  .then((location) => {
                    this.locationData = location;
                    this.createSchemaData(null, null, location);
                    this.seoService.setMetaData({
                      title:
                        location.name +
                        ',' +
                        location.state +
                        ' - Information - Wings Restaurant | Wingstop',
                      description:
                        'Start your online order at ' +
                        location.name +
                        ',' +
                        location.city +
                        ',' +
                        location.state,
                    });
                  })
                  .then((data) => {
                    this.apiService.menu(this.locationData.id).then((menu) => {
                      this.menu = menu;
                      this.specials =
                        menu.getProductsByCategorySlug('specials');
                      this.scheduleCarouselSetup();
                      this.locationData
                        .initializeHours(this.apiService)
                        .then(() => {
                          this.handleStoreFeatures();
                        });
                      resolve(null);
                    });
                  });
              }
            });
        })
        .catch((e) => reject(e));
      this.showDetails = true;
    });
  }

  // Helper function to convert a restaurant to the location modal
  public convertToLocation(restaurant: {}) {
    return new Location(restaurant);
  }

  public getNearbyLocations(restaurants: [], excludedLocation: string) {
    return restaurants.filter((cv: any) => {
      return cv.slug !== this.routeParams['location'];
    });
  }

  // create basket functionality
  public makeBasketAt(location: Location) {
    this.basketAttemptCount = 1;
    this.basketAttempt([location], null);
  }

  private basketAttempt(locations: Location[], address: UserDeliveryAddress) {
    // If a customer already has a basket at this location then don't do it again
    // Send them straight to the menu page to continue ordering
    if (locations.length) {
      if (this.basket?.vendorid === locations[0].id) {
        this.router.navigateByUrl('/menu');
        return;
      }

      // try to make a basket at each location, one by one, until someone accepts our address
      let locationToTry = locations.shift();
      this.basketAttemptCount--;
      GlobalService.beginOrderAt(
        this.router,
        this.appStateActions,
        this.appStore,
        this.productService,
        locationToTry,
        address ? address.toDeliveryAddress() : null,
        this.basket,
        this.product
      ).catch((reason: any) => {
        if (
          reason &&
          reason.error &&
          reason.error.message &&
          this.basketAttemptCount === 0
        ) {
          this.appStateActions.openAlertModalWith(
            "Sorry, something's wrong",
            reason.error.message,
            'Okay',
            null,
            null,
            true,
            false,
            () => {
              this.setLoading(false);
              this.errorCannotDeliverToThatAddress = true;
            }
          );
        } else {
          this.basketAttemptCount > 0
            ? this.basketAttempt(locations, address)
            : null;
        }
      });
    } else {
      this.setLoading(false);
      if (address) {
        this.errorCannotDeliverToThatAddress = true;
      }
    }
  }

  private getKeyByValue(object: {}, value: string) {
    let tempVal = value.replace('-', ' ');
    return Object.keys(object).find(
      (key) => object[key].toLowerCase() === tempVal.toLowerCase()
    );
  }

  public setLoading(isLoading = true) {
    if (isLoading) {
      this.loading = true;
      this.loadingPromise = this.defer();
    } else {
      this.loading = false;
      this.loadingPromise.resolve();
    }
  }

  public defer() {
    let res, rej;

    let promise = new Promise((resolve, reject) => {
      res = resolve;
      rej = reject;
    }) as any;

    promise.resolve = res;
    promise.reject = rej;

    return promise;
  }

  private handleStoreFeatures() {
    if (this.locationData) {
      this.showCokeFreestyle =
        this.cokeFreestyleEnabledInCms &&
        this.locationData.hasCokeFreestyle() &&
        !this.locationData.isTempClosed;
    }
  }

  // Location details functionality
  // setting up specials
  // check if specials are available
  public specialsAreAvailable() {
    return this.specials && this.specials.length;
  }

  // setup schedule carousel
  private scheduleCarouselSetup() {
    if (this.specialsAreAvailable()) {
      this.specialsSliderConfig = {
        arrows: true,
        autoplay: true,
        autoplaySpeed: 10000,
        mobileFirst: true,
        responsive: [
          {
            breakpoint: 320,
          },
          {
            breakpoint: 850,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 1366,
            settings: {
              slidesToShow: 3,
            },
          },
        ],
      };
      this.spiceSliderConfig = {
        arrows: false,
        draggable: false,
        waitForAnimate: false,
        speed: 200,
        initialSlide: 2,
      };
    } else {
      this.spiceSliderConfig = {
        mobileFirst: true,
        arrows: false,
        draggable: false,
        waitForAnimate: false,
        speed: 200,
        initialSlide: 2,
      };
    }
  }

  public getImage(categoryProduct: CategoryProduct) {
    if (categoryProduct.imagefilename) {
      return categoryProduct.getProductImage(this.menu, 'desktop-menu');
    } else {
      return '';
    }
  }

  public externalSite(event: MouseEvent, whereTo: string = null) {
    event.preventDefault();

    this.appStateActions.openAlertModalWith(
      'External Site',
      'You are opening an external site',
      'Ok',
      null,
      'Cancel',
      true,
      false,
      (result: string) => {
        if (result === 'ok') {
          if (whereTo) {
            window.open(whereTo);
          } else {
            let target = <HTMLAnchorElement>event.target;
            window.open(target.href);
          }
        }
      }
    );
  }

  // Helper function for api calls
  public getSecondaryParams(slug: string) {
    const temp = slug.split('-');
    const regex = new RegExp(/(?!la)(\b[A-z]{2}\b)|(wingstop)|(\d+)/);
    const regex2 = new RegExp(/(\b[A-z]{2}\b)|(wingstop)|(\d+)/);
    const final = temp.filter((cv) => {
      if (this.routeParams['city'].toLowerCase() !== 'louisiana') {
        return !regex.test(cv);
      } else {
        return !regex2.test(cv);
      }
    });
    return final.join('-');
  }

  // Favorite Location code
  public isAFavLocation(location: Location) {
    return (
      this.favLocations &&
      this.favLocations.find((element) => {
        return element.id === location.id;
      })
    );
  }
  public saveAsFavorite(event: any, location: Location) {
    event.preventDefault();
    this.toggleFavorite(location);
  }

  // make the given location as favorite/not favorite as appropriate
  public toggleFavorite(location: Location) {
    // if there's already a heart
    if (this.saved) {
      // notify the server of the change
      this.appStateActions.deleteFavoriteLocation(location);
    } else {
      // else, this location is a new favorite
      // tell the server about the change
      this.appStateActions.addFavoriteLocation(location);
    }

    this.saved = !this.saved;
  }

  // formatting function
  public removeSpaces(string: string) {
    return string.replace(/ /g, '-').toLowerCase();
  }

  public removeDashes(string: string) {
    return string.replace(/-/g, ' ');
  }

  formattedAboutSection() {
    if (this.showDetails && this.nomnomData.about && this.locationData) {
      return this.nomnomData.about
        .replace(/\[store]/gi, this.titleCase(this.locationData.name))
        .replace(
          /\[State]/gi,
          this.titleCase(this.removeDashes(this.currentState))
        )
        .replace(
          /\[City]/gi,
          this.titleCase(this.removeDashes(this.locationData.city))
        )
        .replace(
          'Wingstop, Where Flavor Gets Its Wings™',
          '<b>Wingstop, Where Flavor Gets Its Wings™</b>'
        )
        .replace(/\n\r?/g, '<br />');
    }
    if (this.showDetails && this.locationData) {
      return environment.locationPageAboutSection
        .replace(/\[store]/gi, this.titleCase(this.locationData.name))
        .replace(
          /\[State]/gi,
          this.titleCase(this.removeDashes(this.currentState))
        )
        .replace(
          /\[City]/gi,
          this.titleCase(this.removeDashes(this.locationData.city))
        );
    }
    if (this.showCity && this.cityData) {
      return environment.cityAboutSection.replace(
        /\[City]/gi,
        this.titleCase(this.removeDashes(this.cityData.name))
      );
    }
    return environment.baseAboutSection.replace(
      /\[State]/gi,
      this.titleCase(this.removeDashes(this.currentState))
    );
  }

  public titleCase(str: any) {
    return str
      .toLowerCase()
      .split(' ')
      .map((word: string) => {
        return word.replace(word[0], word[0].toUpperCase());
      })
      .join(' ');
  }

  slickPause(modal: string) {
    this.slickModal.slickPause();
    this.slickModalPlaying = false;
  }
  slickPlay(modal: string) {
    this.slickModal.slickPlay();
    this.slickModalPlaying = true;
  }

  createSchemaData(stateData?: State, cityData?: City, locationData?: any) {
    if (cityData) {
      const schema = [
        {
          '@context': 'http://schema.org',
          '@type': 'Thing',
          name: 'Wingstop',
          url: this.document.location.origin,
          description: this.formattedAboutSection(),
        },
        {
          '@context': 'http://schema.org',
          '@type': 'City',
          name: cityData.name,
        },
        {
          '@context': 'http://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'ListItem',
              position: 1,
              name: 'Home',
              item: this.document.location.origin,
            },
            {
              '@type': 'ListItem',
              position: 2,
              name: cityData.stateName,
              item:
                this.document.location.origin +
                '/' +
                cityData.stateName.toLowerCase(),
            },
            {
              '@type': 'ListItem',
              position: 3,
              name: cityData.name,
              item: this.getFriendlyLastBreadcrumbUrl(),
            },
          ],
        },
      ];
      this.seoService.addStructuredData(schema);
    } else if (locationData) {
      const schema = [
        {
          '@context': 'http://schema.org',
          '@type': 'Restaurant',
          name: locationData.name,
          url: this.document.location.origin,
          description: this.formattedAboutSection(),
          address: {
            '@type': 'PostalAddress',
            addressLocality: locationData.city,
            addressRegion: locationData.state,
            postalCode: locationData.zip,
            streetAddress: locationData.streetaddress,
          },
          geo: {
            '@type': 'GeoCoordinates',
            latitude: locationData.latitude,
            longitude: locationData.longitude,
          },
          telephone: locationData.telephone,
          openingHours: this.seoService.getStoreBusinessHours(
            locationData?.calendars[0]
          ),
          hasMenu: `${this.document.location.origin}/location/${locationData.slug}/menu`,
          servesCuisine: 'American, Chicken Wings',
        },
        {
          '@context': 'http://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'ListItem',
              position: 1,
              name: 'Home',
              item: this.document.location.origin,
            },
            {
              '@type': 'ListItem',
              position: 2,
              name:
                this.currentState.charAt(0).toUpperCase() +
                this.currentState.slice(1),
              item: `${this.document.location.origin}/${this.currentState}`,
            },
            {
              '@type': 'ListItem',
              position: 3,
              name: locationData.city,
              item: this.getFriendlyCityUrl(),
            },
            {
              '@type': 'ListItem',
              position: 4,
              name: locationData.name,
              item: this.getFriendlyLastBreadcrumbUrl(),
            },
          ],
        },
      ];
      this.seoService.addStructuredData(schema);
    } else {
      const schema = [
        {
          '@context': 'http://schema.org',
          '@type': 'State',
          name: stateData.displayName,
        },
        {
          '@context': 'http://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'ListItem',
              position: 1,
              item: {
                name: 'Home',
                '@id': this.document.location.origin,
              },
            },
            {
              '@type': 'ListItem',
              position: 2,
              item: {
                name: stateData.displayName,
                '@id': this.getFriendlyLastBreadcrumbUrl(),
              },
            },
          ],
        },
      ];
      this.seoService.addStructuredData(schema);
    }
  }

  public toggleHours(value: string) {
    this.activeHours = value;
  }

  returnHoursLabel() {
    if (!this.locationData) {
      return;
    }
    switch (this.activeHours) {
      case 'carryout':
        return this.locationData.carryoutHoursLabel;
      case 'curbside':
        return this.locationData.curbsideHoursLabel;
      case 'delivery':
        return this.locationData.dispatchHoursLabel;
    }
  }
  // On destroy
  public ngOnDestroy() {
    this.subscriptions.map((s) => s.unsubscribe());
    this.appStateActions.setMap(null);
    this.appStateActions.clearLocations();
  }

  getPosition(location: Location): LatLngLiteral {
    return { lat: location.latitude, lng: location.longitude };
  }

  // Ensure no params are in the URL (i.e wingstop.com/california/los-angeles?test=someParam)
  public getFriendlyLastBreadcrumbUrl() {
    return this.document.location.origin + this.document.location.pathname;
  }

  // Get the City equivalent of the last breadcrumb URL
  // Helps to get correct string on messy URLs (i.e dev.wingstop.com/texas/humble-%28atascocita%29)
  public getFriendlyCityUrl() {
    let url = this.getFriendlyLastBreadcrumbUrl();
    return url.substring(0, url.lastIndexOf('/'));
  }
}
