<div
  class="mobile-app-banner"
  *ngIf="(bannerEnabled$ | async) && (showAppBanner$ | async)">
  <button
    class="mobile-app-banner__close"
    type="button"
    aria-label="Click to dismiss the Wingstop app reminder."
    (click)="closeAppBanner(true)">
    <svg type="close"></svg>
  </button>
  <img
    role="presentation"
    class="mobile-app-banner__icon"
    src="/assets/images/wingstop-app-store-icon.png" />
  <div class="mobile-app-banner__info">
    <p>Wingstop</p>
    <p>Wingstop Restaurants, Inc.</p>
    <div class="mobile-app-banner__stars">
      <svg type="mobile-app-star-full" aria-hidden="true"></svg>
      <svg type="mobile-app-star-full" aria-hidden="true"></svg>
      <svg type="mobile-app-star-full" aria-hidden="true"></svg>
      <svg type="mobile-app-star-full" aria-hidden="true"></svg>
      <svg type="mobile-app-star-full" aria-hidden="true"></svg>
    </div>
    <p *ngIf="isAndroid">GET &mdash; In Google Play</p>
    <p *ngIf="isIos && !isSafari">GET &mdash; On the App Store</p>
  </div>
  <div class="mobile-app-banner__button">
    <a
      *ngIf="isAndroid"
      (click)="onBannerDownloadClick()"
      aria-label="Click to view app. Opens in the play store."
      [href]="
        'https://play.google.com/store/apps/details?id=com.mobilaurus.wingstopandroid&hl=en_US'
      ">
      View
    </a>
    <a
      *ngIf="isIos && !isSafari"
      (click)="onBannerDownloadClick()"
      aria-label="Click to view app. Opens in the app store."
      [href]="'https://apps.apple.com/us/app/wingstop/id454719277'">
      View
    </a>
  </div>
</div>
