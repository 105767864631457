<div class="card text-left">
  <div
    class="card-body"
    [class.coming-soon]="cardData | locationComingSoon"
    [class.temp-closed]="cardData.isTempClosed"
    [class.now-open]="cardData | locationNowOpen">
    <div
      *ngIf="(cardData | locationComingSoon) || (cardData | locationNowOpen)"
      class="coming-soon-container">
      <app-coming-soon-label
        [nowOpen]="cardData | locationNowOpen"></app-coming-soon-label>
    </div>
    <div *ngIf="cardData.isTempClosed" class="temp-closed-container">
      <app-temp-closed-label></app-temp-closed-label>
    </div>
    <p class="card-title font-weight-bold">{{ cardData.name }}</p>
    <div class="card-address">
      <p class="card-subtitle text-muted address-text">
        {{ cardData.streetaddress }}, {{ cardData.city }}, {{ cardData.state }}
      </p>
      <p class="card-subtitle text-muted address-text">
        {{ cardData?.crossstreet }}
      </p>
    </div>
    <div class="card-item d-flex">
      <svg type="clock" aria-hidden="true" class="clock-icon"></svg>
      <p
        class="schedule"
        *ngIf="
          cardData.hoursLabelToday &&
          !(cardData.isTempClosed || (cardData | locationComingSoon))
        ">
        Open {{ cardData?.hoursLabelToday }}
      </p>
      <p class="schedule" *ngIf="cardData | locationComingSoon">Coming Soon</p>
      <p class="schedule" *ngIf="cardData.isTempClosed">Temporarily Closed</p>
    </div>
    <div
      class="d-flex location-details font-weight-bold align-items-center card-item">
      <a
        class="info-icon"
        [routerLink]="routerLink"
        role="button"
        attr.aria-label="'Open {{ cardData.name }} information dialog">
        <svg type="info_fill" aria-hidden="true"></svg>
      </a>
      <a [routerLink]="routerLink" role="button">
        <p class="card-text">Location details</p>
      </a>
    </div>
    <div *ngIf="!cardData.isTempClosed" class="phone card-item">
      <a href="tel:{{ cardData.telephone }}">
        <svg type="phone" aria-hidden="true" class="phone-icon"></svg>
        <span>{{ cardData.telephone }}</span>
      </a>
    </div>
    <div class="navbar-delivery-actions button-container card-item">
      <div
        class="btn-group"
        [class.custom-disabled]="
          cardData.isTempClosed || (cardData | locationComingSoon)
        ">
        <div
          class="btn py-1 px-4 btn-primary d-flex align-items-center justify-content-center w-50"
          *ngIf="cardData.canCarryout()"
          (click)="carryoutOrder()">
          <svg type="carryout" class="text-white" aria-hidden="true"></svg>
          <div class="text-left ml-1">
            <span class="micro d-block">Order</span>
            <span>Carryout</span>
          </div>
        </div>
        <a
          (click)="deliveryOrder()"
          class="btn py-1 px-4 btn-primary d-flex align-items-center justify-content-center w-50"
          *ngIf="cardData.canDeliver()">
          <svg type="delivery" class="text-white" aria-hidden="true"></svg>
          <div class="text-left ml-1">
            <span class="micro d-block">Order</span>
            <span>Delivery</span>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
