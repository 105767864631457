import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-coming-soon-label',
  templateUrl: './coming-soon-label.component.html',
  styleUrls: ['./coming-soon-label.component.scss'],
})
export class ComingSoonLabelComponent implements OnInit {
  @Input() nowOpen: boolean = false;
  constructor() {}

  ngOnInit(): void {}
}
