import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-temp-closed-label',
  templateUrl: './temp-closed-label.component.html',
  styleUrls: ['./temp-closed-label.component.scss'],
})
export class TempClosedLabelComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
