import { CommonModule } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Location } from '@wingstop/models/location/location.model';
import { LocationComingSoonPipe } from '@wingstop/pipes/location-coming-soon.pipe';
import { LocationNowOpenPipe } from '@wingstop/pipes/location-now-open.pipe';

@Component({
  selector: 'app-location-card',
  templateUrl: './location-card.component.html',
  styleUrls: ['./location-card.component.scss'],
})
export class LocationCardComponent implements OnInit {
  @Input() cardData: Location = null;
  @Input() selectButtonCallback: Function = () => {};
  @Input() stateRoute: string;
  routerLink: string;

  constructor(
    private locationComingSoonPipe: LocationComingSoonPipe,
    private locationNowOpenPipe: LocationNowOpenPipe,
    private router: Router
  ) {}

  ngOnInit() {
    this.formatRouterLink();
  }

  carryoutOrder() {
    if (
      this.cardData.isTempClosed ||
      this.locationComingSoonPipe.transform(this.cardData)
    ) {
      return;
    }
    this.selectButtonCallback(this.cardData);
  }

  deliveryOrder() {
    if (
      this.cardData.isTempClosed ||
      this.locationComingSoonPipe.transform(this.cardData)
    ) {
      return;
    }
    this.router.navigateByUrl('/order/action/delivery');
  }

  formatRouterLink() {
    this.routerLink = `/${this.stateRoute}/${this.cardData.city.replace(
      /\s/g,
      '-'
    )}/${this.cardData.slug}`.toLowerCase();
  }
}
