import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-coke-freestyle',
  templateUrl: './coke-freestyle.component.html',
  styleUrls: ['./coke-freestyle.component.scss'],
})
export class CokeFreestyleComponent implements OnInit {
  @Input() message: string = 'Coca-Cola Freestyle Available';
  @Input() labelStyle: any = {}; // Custom style for label
  @Input() iconSize: string = null; // specific size for the icon

  constructor() {}

  ngOnInit() {}
}
