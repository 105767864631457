<div
  [class]="
    (variant ? 'range range-' + variant : 'range') +
    ' ' +
    extraClass +
    ' ' +
    heatClass
  "
  (touchend)="preventZoom($event)">
  <div class="range-control-group">
    <button
      attr.aria-label="{{ ariaDescription }}, {{ ariaDescriptionDecrease }}, {{
        control?.controls.quantity
      }}"
      *ngIf="control"
      type="button"
      [ngClass]="'btn range-control ' + 'range-control-' + buttonVariant"
      [disabled]="disabled"
      (click)="updateControlValue(control.controls.quantity, -1, $event)">
      <svg type="control-minus" alt=""></svg>
    </button>
    <button
      attr.aria-label="{{ ariaDescription }}, {{ ariaDescriptionDecrease }}, {{
        control?.controls.quantity
      }}"
      *ngIf="!control"
      type="button"
      [ngClass]="'btn range-control ' + 'range-control-' + buttonVariant"
      [disabled]="disabled"
      (click)="updateValue(-1, $event)">
      <svg type="control-minus" alt=""></svg>
    </button>
    <span *ngIf="minusButtonLabel" class="range-control-label">
      {{ minusButtonLabel }}
    </span>
  </div>
  <div class="range-slider">
    <nouislider
      attr.aria-controls="{{ ariaControlsId ? ariaControlsId : 'range-value' }}"
      *ngIf="!control"
      [min]="min"
      [max]="max"
      [step]="step"
      [(ngModel)]="value"
      [tooltips]="tooltips"
      (ngModelChange)="onChange($event)"
      (keydown)="onKeydown($event)"
      [disabled]="disabled"></nouislider>
    <ng-container [formGroup]="control" *ngIf="control">
      <nouislider
        attr.aria-controls="{{
          ariaControlsId ? ariaControlsId : 'range-value'
        }}"
        [config]="{ cssClasses: sliderCssClasses }"
        [min]="min"
        [max]="max"
        [step]="step"
        [tooltips]="tooltips"
        [formControl]="control.controls.quantity"
        (ngModelChange)="onChange($event)"
        (keydown)="onKeydown($event)"
        attr.data-cy="{{ ariaDescription }} slider"></nouislider>
    </ng-container>
  </div>
  <div class="range-control-group">
    <button
      attr.aria-label="{{ ariaDescription }}, {{ ariaDescriptionIncrease }}, {{
        control?.controls.quantity
      }}"
      *ngIf="control"
      type="button"
      [ngClass]="'btn range-control ' + 'range-control-' + buttonVariant"
      [disabled]="disabled"
      (click)="updateControlValue(control.controls.quantity, +1, $event)">
      <svg type="control-plus" alt=""></svg>
    </button>
    <button
      attr.aria-label="{{ ariaDescription }}, {{ ariaDescriptionIncrease }}, {{
        control?.controls.quantity
      }}"
      *ngIf="!control"
      type="button"
      [ngClass]="'btn range-control ' + 'range-control-' + buttonVariant"
      [disabled]="disabled"
      (click)="updateValue(+1, $event)">
      <svg type="control-plus" alt=""></svg>
    </button>
    <span *ngIf="plusButtonLabel" class="range-control-label">
      {{ plusButtonLabel }}
    </span>
  </div>
  <div id="range-value" class="text-hide" aria-live="polite">{{ value }}</div>
</div>
