<div class="container-fluid" [ngBusy]="{ busy: locationPagesBusy }">
  <div *ngIf="showState === true">
    <ng-container
      *ngTemplateOutlet="
        stateWrapper;
        context: { state: stateData }
      "></ng-container>
  </div>
  <div *ngIf="showCity === true">
    <ng-container
      *ngTemplateOutlet="
        cityWrapper;
        context: { city: cityData }
      "></ng-container>
  </div>
  <div *ngIf="showDetails && locationData">
    <ng-container
      *ngTemplateOutlet="
        detailsWrapper;
        context: { location: locationData }
      "></ng-container>
  </div>

  <ng-template #stateWrapper let-state="state">
    <div class="row location-banner bg-gold-50">
      <div class="location-container">
        <h1 class="h2">Wingstop Locations in {{ stateData?.displayName }}</h1>
        <button routerLink="/order" class="btn btn-success">
          Find a location
          <svg type="arrow-long-right" alt=""></svg>
        </button>
      </div>
      <div class="location-image">
        <img
          src="/assets/images/location-image-full.png"
          alt="Classic Lemon Pepper Boat"
          aria-hidden="true"
          class="img-fluid" />
      </div>
    </div>
    <div class="cities row">
      <div *ngFor="let city of stateData?.cities" class="col-md-4 city-item">
        <div class="city-name">
          <a
            [routerLink]="
              '/' +
              stateData.fullName.toLowerCase() +
              '/' +
              removeSpaces(city.name)
            "
            class="h6">
            {{ city.name }}
          </a>
        </div>
        <div class="city-counter">({{ city.count }})</div>
      </div>
    </div>
  </ng-template>
  <ng-template #cityWrapper let-city="city">
    <div class="row location-banner bg-gold-50">
      <div class="location-container">
        <h1 class="h2">
          Wingstop Locations in {{ city?.name }}, {{ city?.stateName }}
        </h1>
        <button routerLink="/order" class="btn btn-success">
          Find a location
          <svg type="arrow-long-right" alt=""></svg>
        </button>
      </div>
      <div class="location-image">
        <img
          src="/assets/images/location-image-full.png"
          alt="Classic Lemon Pepper Boat"
          aria-hidden="true"
          class="img-fluid" />
      </div>
    </div>
    <div class="row restaurants">
      <div
        *ngFor="let restaurant of cityData?.restaurants"
        class="col-md-6 col-lg-4 restaurants-card">
        <app-location-card
          [cardData]="convertToLocation(restaurant)"
          [stateRoute]="currentState"
          [selectButtonCallback]="selectButtonCallback"></app-location-card>
      </div>
    </div>
  </ng-template>

  <ng-template #detailsWrapper let-location="location">
    <div class="location-details">
      <div class="location-map">
        <google-map
          *ngIf="gMapApiLoaded$ | async"
          height="300px"
          width="100%"
          [options]="{
            center: { lat: location?.latitude, lng: location?.longitude }
          }">
          <map-marker
            [position]="getPosition(location)"
            [iconUrl]="'/assets/icons/marker_black.svg'"></map-marker>
        </google-map>
      </div>
      <div class="row detail-hours">
        <div
          *ngIf="location | locationComingSoon"
          class="coming-soon-container">
          <app-coming-soon-label></app-coming-soon-label>
        </div>
        <div *ngIf="location.isTempClosed" class="temp-closed-container">
          <app-temp-closed-label></app-temp-closed-label>
        </div>
        <div *ngIf="location | locationNowOpen" class="coming-soon-container">
          <app-coming-soon-label
            [nowOpen]="location | locationNowOpen"></app-coming-soon-label>
        </div>
        <div class="general-info col-md-6 col-lg-5">
          <h1 class="h3">{{ location?.name }}</h1>
          <div class="d-flex location-info">
            <svg type="location" class="location-icon" aria-hidden="true"></svg>
            <p class="detail-text">{{ location?.streetaddress }}</p>
          </div>
          <div class="d-flex">
            <p *ngIf="location?.distance !== 0" class="distance-text">
              {{ location?.distance }} miles away
            </p>
          </div>
          <div *ngIf="!location.isTempClosed">
            <a
              href="tel:{{ location?.telephone }}"
              attr.aria-label="'Call {{ location?.name }}"
              class="d-flex">
              <svg type="phone" aria-hidden="true"></svg>
              <p class="detail-text">{{ location?.telephone }}</p>
            </a>
          </div>
          <div *ngIf="!location.isTempClosed">
            <a
              href="#"
              role="button"
              attr.aria-label="'Add {{ location?.name }} to favorites"
              (click)="saveAsFavorite($event, location)"
              class="d-flex favorite-button">
              <svg
                type="heart{{ !saved ? '-outline' : '' }}"
                [class.text-danger]="saved"
                aria-hidden="true"></svg>
              <p class="detail-text">Favorite this location</p>
            </a>
          </div>
          <app-coke-freestyle
            *ngIf="showCokeFreestyle"
            [labelStyle]="{ marginLeft: '10px', marginBottom: '0' }"
            [iconSize]="'1.5em'"></app-coke-freestyle>
          <div
            *ngIf="!location.isTempClosed && !(location | locationComingSoon)"
            class="navbar-delivery-actions button-container card-item">
            <div class="btn-group">
              <div
                class="btn py-1 px-4 btn-primary d-flex align-items-center justify-content-center w-100"
                *ngIf="canCarryout"
                (click)="makeBasketAt(location)">
                <svg
                  type="carryout"
                  class="text-white"
                  aria-hidden="true"></svg>
                <div class="text-left ml-1">
                  <span class="micro d-block">Order</span>
                  <span>Carryout</span>
                </div>
              </div>
              <a
                routerLink="/order/action/delivery"
                class="btn py-1 px-4 btn-primary d-flex align-items-center justify-content-center w-100"
                *ngIf="canDeliver">
                <svg
                  type="delivery"
                  class="text-white"
                  aria-hidden="true"></svg>
                <div class="text-left text-white ml-1">
                  <span class="micro d-block">Order</span>
                  <span>Delivery</span>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 store-hours">
          <div
            class="hours-list col-lg-5"
            [class.col-lg-12]="
              location.isTempClosed || (location | locationComingSoon)
            ">
            <h2 class="h5">Store hours</h2>
          </div>
          <div *ngIf="location | locationComingSoon" class="col-lg-12">
            Coming soon
          </div>
          <div *ngIf="location.isTempClosed" class="col-lg-12">
            This location is temporarily closed.
          </div>
          <div
            *ngIf="
              !location.isTempClosed &&
              !(location | locationComingSoon) &&
              !(location | locationComingSoon)
            "
            class="hour-labels d-flex justify-content-between col-lg-7 border-bottom-shade">
            <span
              *ngIf="locationData?.canCarryout()"
              [ngClass]="
                activeHours === 'carryout'
                  ? 'active-hours text-center hour-label'
                  : 'text-center hour-label'
              "
              (click)="toggleHours('carryout')">
              Carryout
            </span>
            <span
              *ngIf="locationData?.supportscurbside"
              [ngClass]="
                activeHours === 'curbside'
                  ? 'active-hours text-center hour-label'
                  : 'text-center hour-label'
              "
              (click)="toggleHours('curbside')">
              Curbside
            </span>
            <span
              *ngIf="locationData?.canDeliver()"
              [ngClass]="
                activeHours === 'delivery'
                  ? 'active-hours text-center hour-label'
                  : 'text-center hour-label'
              "
              (click)="toggleHours('delivery')">
              Delivery
            </span>
          </div>
          <div
            *ngIf="
              !locationData.isTempClosed && !(locationData | locationComingSoon)
            "
            class="hours col-lg-7 pt-3 pb-3">
            <div class="carryout-hours-list">
              <div
                *ngFor="let hour of returnHoursLabel()"
                class="row location-hours">
                <div class="day col-md-5 col-6">{{ hour.day }}</div>
                <div
                  class="hours col-md-7 col-6"
                  [attr.aria-label]="activeHours + ' hours'">
                  {{ hour.hours }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="row specials" *ngIf="specialsAreAvailable()">
          <h2 class="h3">Location Specials</h2>
          <div class="container-fluid">
            <div class="bg-white">
              <div *ngIf="specials?.length > 3">
                <button
                  class="slider-modal-control"
                  (click)="slickPause('slickModal')"
                  *ngIf="slickModalPlaying">
                  <svg
                    width="34px"
                    height="34px"
                    viewBox="0 0 34 34"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>Pause</title>
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd">
                      <g
                        id="WS-Order-eClub-2"
                        transform="translate(-1367.000000, -631.000000)">
                        <g
                          id="Pause"
                          transform="translate(1368.000000, 632.000000)">
                          <g
                            transform="translate(11.000000, 11.000000)"
                            fill="#333333">
                            <rect x="0" y="0" width="3" height="10"></rect>
                            <rect x="7" y="0" width="3" height="10"></rect>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </button>
                <button
                  class="slider-modal-control"
                  (click)="slickPlay('slickModal')"
                  *ngIf="!slickModalPlaying">
                  <
                  <svg
                    width="34px"
                    height="34px"
                    viewBox="0 0 34 34"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>Play</title>
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd">
                      <g id="Play" transform="translate(1.000000, 1.000000)">
                        <polygon
                          fill="#333333"
                          points="13 10 21 16 13 22"></polygon>
                      </g>
                    </g>
                  </svg>
                </button>
              </div>
              <ngx-slick-carousel
                [config]="specialsSliderConfig"
                #slickModal="slick-carousel"
                class="carousel col-xl-8 offset-xl-2 location-pages-specials-carousel">
                <div
                  ngxSlickItem
                  class="slide"
                  *ngFor="let special of specials">
                  <div class="hero-card">
                    <div
                      class="product-image"
                      [style.background-image]="
                        'url(' + getImage(special) + ')'
                      "></div>
                    <div class="heading">
                      <h3 class="heading-name">{{ special.name }}</h3>
                      <p class="heading-description">
                        {{ special.description }}
                      </p>
                    </div>
                    <a
                      class="btn btn-secondary btn-block action-button"
                      [routerLink]="
                        '/location/' +
                        routeParams['location'] +
                        '/menu/specials/' +
                        special.slug
                      ">
                      Customize It
                      <svg type="arrow-long-right"></svg>
                    </a>
                  </div>
                </div>
              </ngx-slick-carousel>
            </div>
          </div>
        </div>
        <div class="row iconic-flavors-content">
          <!--ICONIC FLAVORS PANEL-->
          <div
            *ngIf="spiceSliderConfig !== null"
            class="iconic-flavors col-lg-6 text-center">
            <div class="header">
              <h2 class="h2">Iconic Flavors</h2>
              <p>
                Explore our saucy or dry rub flavors that range from mild to
                hot, in sweet or savory.
              </p>
            </div>

            <div class="app-range-container">
              <app-range
                extraClass="fire-icon"
                [value]="2"
                [colorfulHandle]="true"
                [tooltips]="false"
                minusButtonLabel="Mild"
                (valueChanged)="slickSpice.slickGoTo($event)"
                [step]="null"
                [ariaControlsId]="'iconic-flavors-slider'"
                [ariaDescription]="'Explore our flavors'"
                [ariaDescriptionIncrease]="'Hot, increase hotness'"
                [ariaDescriptionDecrease]="'Mild, decrease hotness'"
                plusButtonLabel="Hot"></app-range>
            </div>
            <ngx-slick-carousel
              [config]="spiceSliderConfig"
              id="iconic-flavors-slider"
              #slickSpice="slick-carousel"
              aria-live="polite"
              class="carousel col-xl-8 offset-xl-2">
              <!--LEVEL 0-->
              <div ngxSlickItem>
                <app-spice-card
                  name="Hawaiian"
                  iconName="flavor-hawaiian"
                  description="Sweet and tangy, this sauce has a fruity blend of island citrus balanced with rich Asian flavors."
                  [spiceLevel]="0"></app-spice-card>
              </div>
              <div ngxSlickItem>
                <app-spice-card
                  name="Garlic Parmesan"
                  iconName="flavor-garlic-parmesan"
                  description="Savory garlic, buttery Parmesan cheese, this great blend of flavors is worth wrecking your breath for."
                  [spiceLevel]="0"></app-spice-card>
              </div>

              <!--LEVEL 1-->
              <div ngxSlickItem>
                <app-spice-card
                  name="Lemon Pepper"
                  iconName="flavor-lemon-pepper"
                  description="Made with hearty pepper and zesty lemon, this sauce has people craving it from coast to coast."
                  [spiceLevel]="1"></app-spice-card>
              </div>

              <!--LEVEL 2-->
              <div ngxSlickItem>
                <app-spice-card
                  name="Hickory Smoked BBQ"
                  iconName="flavor-hickory-smoked-bbq"
                  description="Bold, smoky, sweet and rich, this barbeque sauce has got it all. It’s all the flavors of a summer night distilled into one."
                  [spiceLevel]="2"></app-spice-card>
              </div>
              <div ngxSlickItem>
                <app-spice-card
                  name="Mild"
                  iconName="flavor-mild"
                  description="It’s all the flavor of Original Hot without the burn. It may be mild without the wild, but it’s still got that classic buffalo spirit."
                  [spiceLevel]="2"></app-spice-card>
              </div>

              <!--LEVEL 3-->
              <div ngxSlickItem>
                <app-spice-card
                  name="Louisiana Rub"
                  iconName="flavor-louisiana-rub"
                  description="A crispy dry rub, a hint of garlic, plus some Deep South Cajun love and you’ve got a wing that’s simply addictive."
                  [spiceLevel]="3"></app-spice-card>
              </div>
              <div ngxSlickItem>
                <app-spice-card
                  name="Spicy Korean Q"
                  iconName="flavor-spicy-korean-q"
                  description="The Legend. A harmonious balance of sweet and spicy. Ginger and garlic meet Sriracha & crushed red pepper to guide you on a journey to flavor nirvana."
                  [spiceLevel]="3"></app-spice-card>
              </div>
              <div ngxSlickItem>
                <app-spice-card
                  name="Original Hot"
                  iconName="flavor-original-hot"
                  description="It’s the sauce we first tossed, the one that made us famous. It’s the classic blend of heat and tang that gives this wing its flavorful kick."
                  [spiceLevel]="3"></app-spice-card>
              </div>

              <!--LEVEL 4-->
              <div ngxSlickItem>
                <app-spice-card
                  name="Cajun"
                  iconName="flavor-cajun"
                  description="It’s all the warmth of Original Hot mixed with the bold, zesty flavors of Louisiana. It’s bound to make you sing."
                  [spiceLevel]="4"></app-spice-card>
              </div>
              <div ngxSlickItem>
                <app-spice-card
                  name="Mango Habanero"
                  iconName="flavor-mango-habanero"
                  description="Don’t be fooled. This sweet wing has got a bite. Just when you think you’re swimming in sweetness, the habanero kicks, full throttle."
                  [spiceLevel]="4"></app-spice-card>
              </div>

              <!--LEVEL 5-->
              <div ngxSlickItem>
                <app-spice-card
                  name="Atomic"
                  iconName="flavor-atomic"
                  description="Sauce, sweat and tears. This is the hottest we got for the boldest of the bold. Dare to wing it."
                  [spiceLevel]="5"></app-spice-card>
              </div>
            </ngx-slick-carousel>

            <a
              class="btn btn-secondary btn-block action-button"
              [routerLink]="['/menu']">
              Browse Our Menu
              <svg type="arrow-long-right"></svg>
            </a>
          </div>
          <div
            class="job-search col-lg-6 d-flex justify-content-center flex-column text-center align-items-center">
            <h2>Looking for a job?</h2>
            <p>
              At Wingstop, we love our employees and would be excited for you to
              join our crew!
            </p>
            <button
              [routerLink]="locationData?.nomnom?.employment_uri || '/careers'"
              class="btn btn-primary">
              Available Jobs
              <svg type="arrow-long-right"></svg>
            </button>
          </div>
        </div>
      </div>
      <div class="row nearby-locations" *ngIf="nearbyLocations.length > 0">
        <h2 class="h3">Nearby Locations</h2>
        <div class="row container-fluid">
          <div *ngFor="let restaurant of nearbyLocations" class="col-lg-4">
            <app-location-card
              [cardData]="restaurant"
              [stateRoute]="currentState"
              [selectButtonCallback]="selectButtonCallback"></app-location-card>
          </div>
        </div>
      </div>
      <div class="row about-location">
        <h2 class="h3">Where Flavor Gets its Wings {{ locationData?.name }}</h2>
        <p [innerHTML]="formattedAboutSection()"></p>
      </div>
      <div class="row mobile-app-banner">
        <div class="col-lg-6">
          <div class="banner-image-left">
            <img
              src="/assets/images/banner-location-image.png"
              alt="Classic Lemon Pepper Boat"
              aria-hidden="true"
              class="img-fluid" />
          </div>
        </div>
        <div
          class="col-lg-6 d-flex justify-content-center flex-column banner-text">
          <img
            src="/assets/images/wingstop-logo-banner.png"
            alt="Classic Lemon Pepper Boat"
            aria-hidden="true"
            class="app-banner-logo" />
          <h2 class="banner-title">Wingstop, where flavor gets its wings</h2>
          <p>Our brand new app has the flavor you crave.</p>
          <div class="logos-app d-flex">
            <a
              class="pointer"
              href="https://apps.apple.com/us/app/wingstop/id454719277">
              <img
                src="/assets/images/apple-icon.svg"
                alt="Classic Lemon Pepper Boat"
                aria-hidden="true"
                class="social-icon apple-icon" />
            </a>
            <a
              class="pointer"
              href="https://play.google.com/store/apps/details?id=com.mobilaurus.wingstopandroid&hl=en_US">
              <img
                src="/assets/images/google-icon.svg"
                alt="Classic Lemon Pepper Boat"
                aria-hidden="true"
                class="social-icon google-icon" />
            </a>
          </div>
        </div>
      </div>
      <div class="row social-media-links d-flex flex-column align-items-center">
        <h3>Check us out on social Media</h3>
        <ul class="nav footer-user">
          <li class="nav-item">
            <a
              [href]="
                nomnomData?.facebook_uri || 'https://www.facebook.com/wingstop/'
              "
              target="_blank"
              rel="”noopener”"
              class="nav-link text-nowrap"
              aria-label="Facebook will open on new tab"
              (click)="
                externalSite(
                  $event,
                  nomnomData?.facebook_uri ||
                    'https://www.facebook.com/wingstop/'
                )
              ">
              <fa-icon [icon]="icons.faFacebookF" size="lg"></fa-icon>
            </a>
          </li>
          <li class="nav-item">
            <a
              [href]="
                nomnomData?.instagram_uri ||
                'https://www.instagram.com/wingstop'
              "
              target="_blank"
              rel="”noopener”"
              class="nav-link text-nowrap"
              aria-label="Instagram will open on new tab"
              (click)="
                externalSite(
                  $event,
                  nomnomData?.instagram_uri ||
                    'https://www.instagram.com/wingstop'
                )
              ">
              <fa-icon [icon]="icons.faInstagram" size="lg"></fa-icon>
            </a>
          </li>
          <li class="nav-item">
            <a
              [href]="
                nomnomData?.twitter_uri || 'https://twitter.com/wingstop/'
              "
              target="_blank"
              rel="”noopener”"
              class="nav-link text-nowrap"
              aria-label="Twitter will open on new tab"
              (click)="
                externalSite(
                  $event,
                  nomnomData?.twitter_uri || 'https://twitter.com/wingstop/'
                )
              ">
              <fa-icon [icon]="icons.faTwitter" size="lg"></fa-icon>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </ng-template>
  <div class="about-section" *ngIf="showState || showCity">
    <div class="row">
      <h2 class="h3">
        For Great Wings in
        {{
          showState ? this.removeDashes(this.currentState) : this.cityData.name
        }}: Get Wingstop
      </h2>
      <p [innerHTML]="formattedAboutSection()"></p>
    </div>
  </div>
</div>
