import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-spice-card',
  templateUrl: './spice-card.component.html',
  styleUrls: ['./spice-card.component.scss'],
})
export class SpiceCardComponent implements OnInit {
  @Input() spiceLevel = 3;
  @Input() name: string;
  @Input() iconName: string;
  @Input() description: string;

  public spiceLevelCounter: string[];
  public spiceColorClass = '';

  private maxSpice = 5;

  public static getSpiceColor(level: number) {
    if (level <= 1) {
      return 'green';
    } else if (level <= 3) {
      return 'orange';
    } else {
      return 'red';
    }
  }

  constructor() {}

  ngOnInit() {
    // angular doesn't support traditional looping (e.g. loop 5 times)
    // so, use a dummy array until this feature is supported
    this.spiceLevelCounter = [];
    for (let x = 1; x <= this.spiceLevel; x++) {
      this.spiceLevelCounter.push(
        SpiceCardComponent.getSpiceColor(this.spiceLevel)
      );
    }
    for (let x = 1; x <= this.maxSpice - this.spiceLevel; x++) {
      this.spiceLevelCounter.push('fade');
    }

    // set the class so the flame icons change color
    this.spiceColorClass =
      'text-' + SpiceCardComponent.getSpiceColor(this.spiceLevel);
  }
}
