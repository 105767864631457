import { BaseModel } from '@wingstop/models/base.model';

export class KeyValuePair extends BaseModel {
  key: any = null;
  value: any = null;

  constructor(values?: any) {
    super();
    this.initialize(values);
  }
}
