<div id="global_messaging_footer"></div>
<footer class="footer" *ngIf="shouldShow$ | async">
  <div class="constrain">
    <ul class="nav h6 lead footer-links footer-corporate">
      <li class="nav-item">
        <a class="nav-link" href="{{ legacy_base_url }}/about-us">About</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/gift-cards">Gift Cards</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="{{ legacy_base_url }}/careers">Careers</a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          href="https://www.wingstopcharities.org"
          target="_blank"
          rel="”noopener”"
          title="Opens in a new tab">
          Wingstop Charities
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          href="https://wingsider.wingstop.com/?utm_source=wingstop&utm_medium=footer&utm_campaign=wingsider"
          target="_blank"
          rel="”noopener”"
          title="Opens in a new tab">
          News & Stories
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="{{ legacy_base_url }}/contact-us">
          Contact Us
        </a>
      </li>
    </ul>

    <ul class="nav small footer-links footer-legal">
      <li class="nav-item">
        <a class="nav-link" href="{{ legacy_base_url }}/terms-of-use">Terms</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="{{ legacy_base_url }}/privacy">Privacy</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="{{ legacy_base_url }}/sitemap">Sitemap</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="{{ legacy_base_url }}/accessibility">
          Accessibility
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          href="https://ir.wingstop.com"
          target="_blank"
          rel="”noopener”"
          title="Opens in a new tab">
          Investor Relations
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="{{ legacy_base_url }}/own-a-wingstop">
          Own a Wingstop
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="{{ legacy_base_url }}/nutrition">
          Nutritional Info
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="{{ legacy_base_url }}/allergens">
          Allergen Info
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="{{ legacy_base_url }}/faq">FAQ</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="{{ legacy_base_url }}/california-privacy">
          California Privacy
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          href="{{ legacy_base_url }}/privacy"
          fragment="dnsmi">
          Do Not Sell My Info
        </a>
      </li>
    </ul>

    <div class="footer-border">
      <div class="col-12 p-0 d-flex justify-content-center align-items-center">
        <ul class="nav footer-user">
          <li class="nav-item">
            <a
              href="https://www.facebook.com/wingstop/"
              target="_blank"
              rel="”noopener”"
              class="nav-link text-nowrap"
              aria-label="Facebook will open in a new tab"
              (click)="
                externalSite($event, 'https://www.facebook.com/wingstop/')
              ">
              <fa-icon [icon]="icons.faFacebookF" size="lg"></fa-icon>
            </a>
          </li>
          <li class="nav-item">
            <a
              href="https://www.instagram.com/wingstop"
              target="_blank"
              rel="”noopener”"
              class="nav-link text-nowrap"
              aria-label="Instagram will open in a new tab"
              (click)="
                externalSite($event, 'https://www.instagram.com/wingstop')
              ">
              <fa-icon [icon]="icons.faInstagram" size="lg"></fa-icon>
            </a>
          </li>
          <li class="nav-item">
            <a
              href="https://twitter.com/wingstop/"
              target="_blank"
              rel="”noopener”"
              class="nav-link text-nowrap"
              aria-label="Twitter will open in a new tab"
              (click)="externalSite($event, 'https://twitter.com/wingstop/')">
              <fa-icon [icon]="icons.faTwitter" size="lg"></fa-icon>
            </a>
          </li>
          <li class="nav-item">
            <a
              href="https://open.spotify.com/user/r1cb3fd7wt2v3nqjbl5p7p5wx"
              target="_blank"
              rel="”noopener”"
              class="nav-link text-nowrap"
              aria-label="Spotify will open in a new tab"
              (click)="
                externalSite(
                  $event,
                  'https://open.spotify.com/user/r1cb3fd7wt2v3nqjbl5p7p5wx'
                )
              ">
              <fa-icon [icon]="icons.faSpotify" size="lg"></fa-icon>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="footer-copyright">
      <p class="small text-muted m-0">
        &copy; Wingstop Restaurants, Inc. {{ copyrightYear }}
      </p>
    </div>
  </div>
</footer>
