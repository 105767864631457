import { Injectable } from '@angular/core';
import { Basket } from '@wingstop/models/basket.model';
import { KeyValuePair } from '@wingstop/models/key-value-pair.model';
import { Subscription } from 'rxjs';
import { AppStateSelectors } from '@wingstop/store/app/app-state.selectors';
import { AppStateActions } from '@wingstop/store/app/app-state.actions';
import { IAppStore } from '@wingstop/store/app-store';
import { State } from '@ngrx/store';
import { environment } from '@wingstop/environments/environment';
import { debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TipService {
  public subscriptions: Subscription[] = [];
  public basketSubscription: Subscription;
  public basket: Basket;
  public setAmounts: number[] = [];
  public setPercentAmounts: number[] = [];
  public percentAmounts = Array<KeyValuePair>();
  public threshold = environment.tipThreshold;
  private defaultTipKey: number;
  public tipErrorMessage: string;

  constructor(
    private appStateSelectors: AppStateSelectors,
    private appStateActions: AppStateActions,
    private appStore: State<IAppStore>
  ) {
    if (
      environment.tipAuto // Auto tip turned on globally
    ) {
      this.watchBasket();
    }

    this.subscriptions.push(
      this.appStateSelectors.tipIndex.subscribe((value) => {
        if (value != null) {
          this.watchBasket();
        }
      })
    );
    this.subscriptions.push(
      this.appStateSelectors.userTip.subscribe((value) => {
        if (value) {
          this.watchBasket();
        }
      })
    );
    this.subscriptions.push(
      this.appStateSelectors.defaultTip.subscribe((value) => {
        if (value) {
          this.watchBasket();
        }
      })
    );
  }

  watchBasket() {
    if (!this.basketSubscription) {
      // Setup the basket subscription
      this.basketSubscription = this.appStateSelectors.basket
        .pipe(debounceTime(1000))
        .subscribe((value) => {
          if (!value) {
            return;
          }
          // Clear the tip if not available
          if (!value.allowstip && value.tip) {
            this.clearTip();
            return;
          }

          // Set tip data based on deliverymode
          if (value) {
            if (
              value.deliverymode === 'pickup' ||
              value.deliverymode === 'dinein'
            ) {
              this.setAmounts = environment.tipAmountDollarsPickup;
              this.setPercentAmounts = environment.tipAmountPercentsPickup;
              this.defaultTipKey = environment.tipIndexDefaultPickup;
            } else {
              this.setAmounts = environment.tipAmountDollars;
              this.setPercentAmounts = environment.tipAmountPercents;
              this.defaultTipKey = environment.tipIndexDefault;
            }
          }

          // Reset the calculated tip amounts
          this.percentAmounts = Array<KeyValuePair>();
          // Compare old and new basket tip or if deliverymode changed
          if (
            value &&
            this.basket &&
            environment.tipResetOnThresholdChange &&
            environment.tipThresholdEnabled
          ) {
            if (
              (value.subtotal >= this.threshold &&
                this.basket.subtotal < this.threshold) ||
              (value.subtotal < this.threshold &&
                this.basket.subtotal >= this.threshold) ||
              (value.subtotal > 0 && this.basket.subtotal === 0) ||
              value.deliverymode !== this.basket.deliverymode
            ) {
              this.appStateActions.applyTip(this.defaultTipKey);
            }
          }
          // Associate the new basket
          this.basket = value;
          // Clear the tip if the basket is empty
          if (value.tip > 0 && value.subtotal === 0) {
            this.setTip(0, false);
            return;
          }
          // Only if the basket supports tip
          if (this.basket && this.basket.allowstip) {
            this.setPercentAmounts.forEach((percent) => {
              this.percentAmounts.push(
                new KeyValuePair({
                  key: percent,
                  value: this.percentage(percent, this.basket.subtotal),
                })
              );
            });
            // If the user hasn't selected a tip, we haven't defaulted a tip, AND
            // there wasn't one already set (reloaded basket) apply the default tip
            if (
              !this.appStore.getValue().appState.userTip &&
              !this.appStore.getValue().appState.defaultTip &&
              !this.basket.tip
            ) {
              this.applyDefaultTip();
            } else if (
              this.appStore.getValue().appState.tipIndex != null &&
              this.basket.tip !==
                this.getValue(this.appStore.getValue().appState.tipIndex)
            ) {
              this.setTipIndex(this.appStore.getValue().appState.tipIndex);
            }
          }
        });
    }
  }

  percentage(percent: number, n: number) {
    return (n * (percent / 100)).toUSD();
  }

  setTip(tip: number, isDefault: boolean) {
    return this.appStateActions
      .applyTip(null, Number(tip) || 0, isDefault)
      .catch((error: any) => {
        this.tipErrorMessage = error.error.message;
      });
  }

  setTipIndex(index: number, isDefault?: boolean) {
    return this.appStateActions.applyTip(
      index,
      index !== null ? this.getValue(index) : index,
      isDefault
    );
  }

  setValue(val: string, isDefault: boolean = null) {
    return this.setTip(Number(val).toUSD(), isDefault);
  }

  applyDefaultTip() {
    return this.setTipIndex(this.defaultTipKey, true);
  }

  clearTip() {
    return this.setTip(0, false);
  }

  getValue(index: number) {
    if (
      this.percentAmounts &&
      Array.isArray(this.percentAmounts) &&
      this.percentAmounts.length
    ) {
      return this.basket.subtotal < this.threshold ||
        !environment.tipThresholdEnabled
        ? this.setAmounts[index]
        : this.percentAmounts[index].value;
    }
  }
}
