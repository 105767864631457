import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { State } from '@ngrx/store';
import { IAppStore } from '@wingstop/store/app-store';
import { ApiService } from '@wingstop/services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OfferModalComponent } from '@wingstop/global/offer-modal/offer-modal.component';
import { AppStateActions } from '@wingstop/store/app/app-state.actions';
import { CMSPromoOffer } from '@wingstop/models/cms-promo-offer.model';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class OfferDetailsResolver implements Resolve<any> {
  constructor(
    public router: Router,
    private store: State<IAppStore>,
    private apiService: ApiService,
    private modalService: NgbModal,
    private appStateActions: AppStateActions
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return new Promise(async (resolve) => {
      const offerId = route.queryParams['offer_id'];
      const redeemId = route.queryParams['redeem'];
      let targetOffer: CMSPromoOffer;

      // if user arrives on site with offerId and redeemId query params
      if (offerId && redeemId) {
        targetOffer = await this.getCMSPromoOffer(offerId);
        this.checkOfferValidity(targetOffer, redeemId, true);
      } // if user navigates to another page or refreshes
      else if (
        this.store.getValue().appState.cmsOffer &&
        this.store.getValue().appState.cmsOfferRedeemCode
      ) {
        this.checkOfferValidity(
          this.store.getValue().appState.cmsOffer,
          this.store.getValue().appState.cmsOfferRedeemCode,
          false
        );
      }

      resolve(null);
    });
  }

  private checkOfferValidity(
    offer: CMSPromoOffer,
    redeemId: string,
    queryParams: boolean
  ) {
    let current = Date.now();
    const offerExpiration = new Date(offer.expirationDate.replace(/-/g, '/'));

    // if there isn't already a timestamp, store it
    if (!this.store.getValue().appState.cmsOfferTimestamp) {
      this.appStateActions.setCmsOfferTimestamp(current);
    }

    // if known expiration date & offer valid OR if expiration date unknown (which results in 'Invalid date') & under 24 hours from first visit
    if (
      (offer.expirationDate &&
        !offer.expirationDate.includes('Invalid date') &&
        current < offerExpiration.getTime()) ||
      (offer.expirationDate &&
        offer.expirationDate.includes('Invalid date') &&
        current <
          this.store.getValue().appState.cmsOfferTimestamp +
            24 * 60 * 60 * 1000)
    ) {
      this.handleValidOffer(offer, redeemId, queryParams);
    } // if known expiration date & offer past due OR expiration date unknown & 24 hours have passed since first visit
    else if (
      (offer.expirationDate &&
        !offer.expirationDate.includes('Invalid date') &&
        current >= offerExpiration.getTime()) ||
      (offer.expirationDate &&
        offer.expirationDate.includes('Invalid date') &&
        current >=
          this.store.getValue().appState.cmsOfferTimestamp +
            24 * 60 * 60 * 1000)
    ) {
      this.appStateActions.openAlertModalWith(
        offer.expirationTitle ? offer.expirationTitle : 'Uh Oh',
        offer.expirationMessage
          ? offer.expirationMessage
          : 'This offer has expired.',
        'OK',
        null,
        null,
        false,
        false,
        null,
        offer.codeApplyErrorImage
          ? offer.codeApplyErrorImage
          : 'https://hway-website.s3.amazonaws.com/wingstop/WING-whoops.png',
        true
      );
      this.appStateActions.setCmsOfferToUse(null);
      this.appStateActions.setCmsOfferRedeemCode(null);
      this.appStateActions.setCmsOfferTimestamp(null);
    }
  }

  // handler for VALID offers
  private handleValidOffer(
    validOffer: CMSPromoOffer,
    redeemId: string,
    queryParams: boolean
  ) {
    // if there is already a coupon in basket, override and replace it with redeemId
    // if no coupon in basket OR if no basket at all, store redeem code
    if (
      (this.store.getValue().appState.basket &&
        this.store.getValue().appState.basket.coupon &&
        this.store.getValue().appState.basket.coupon.couponcode) ||
      (this.store.getValue().appState.basket &&
        !this.store.getValue().appState.basket.coupon) ||
      !this.store.getValue().appState.basket
    ) {
      this.appStateActions.setCmsOfferRedeemCode(redeemId);
    }

    // only show initial modal if user navigates to site from link with query params
    if (queryParams) {
      this.showCMSModal(validOffer);
    }
  }

  private getCMSPromoOffer(offerId: string): Promise<CMSPromoOffer> {
    return new Promise(async (resolve, reject) => {
      await this.apiService
        .getOffer(offerId)
        .then((offer) => {
          // once offer retrieved, set cms offer to use
          this.appStateActions.setCmsOfferToUse(offer);
          resolve(offer);
        })
        .catch((e) => {
          console.log('error fetching offer', e);
          this.appStateActions.setCmsOfferToUse(null);
          this.appStateActions.setCmsOfferRedeemCode(null);
          this.appStateActions.setCmsOfferTimestamp(null);
          this.router.navigate(['/not-found']);

          if (e instanceof HttpErrorResponse) {
            this.appStateActions.openAlertModalWith(
              'Error',
              e.error.error,
              'OK',
              null,
              null,
              false,
              false,
              null
            );
          }
          if (e instanceof TypeError) {
            this.appStateActions.openAlertModalWith(
              'Error',
              'Offer not currently available.',
              'OK',
              null,
              null,
              false,
              false,
              null
            );
          }

          reject();
        });
    });
  }

  private showCMSModal(offer: CMSPromoOffer) {
    if (this.modalService.hasOpenModals()) {
      this.modalService.dismissAll();
    }

    const modalRef = this.modalService.open(OfferModalComponent, {
      windowClass: 'offer-redemption-modal',
      ariaLabelledBy: 'cms-offer-modal-title',
      centered: true,
    });
    modalRef.componentInstance.modalData = offer;
    modalRef.componentInstance.template = 'offerModal';
  }
}
